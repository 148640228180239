import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import {
  operationModeOptions, registerStatusOptions, registerTypeOptions,
  trueOrFalseOptions,
} from '@core/utils/options'
import { can } from '@core/utils/useAccess'
import { getPage, getDepartmentTree, getUserByDeptTree } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const active = ref('youtube')
  const searchRef = ref()
  const groupListOptions = ref([])
  const userListOptions = ref([])
  const applicantOptions = ref([])

  const { message } = useMessage()

  // 设置默认状态
  const calSatatus = () => {
    if (can(['YT频道管理员', 'FB主页管理员'])) {
      return [3] // 待审核
    }

    if (can(['YT领用审核员', 'FB领用审核员'])) {
      return [2] // 待审核
    }

    if (can(['Banner设计组员', 'YT频道设置组员', 'FB频道设置组员'])) {
      return [4, 9]
    }

    return []
  }

  // 查询初始化参数
  const queryDataOri = {
    applicationNo: null,
    contentJson: null,
    registerType: can(['FB排查盗版组员', 'YT排查盗版组员', 'Banner设计组员', 'YT频道设置组员', 'FB频道设置组员']) ? 0 : null, // 默认新开
    operationMode: null,
    operationGroupId: null,
    operatingMemberId: null,
    applicantId: null,
    startDate: null,
    endDate: null,
    status: calSatatus(), // 待审核2,审核拒绝0,待注册3，待建群4, 已完成9,
    bannerDesignFlag: can(['Banner设计组员']) ? 0 : null,
    backendSettingFlag: can(['YT频道设置组员', 'FB频道设置组员']) ? 0 : null,
    operationLeaderId: null,
  }

  const tabsConfig = reactive([
    {
      name: 'YouTube',
      key: 'youtube',
      auth: can(['超级管理员', '研发管理员', '总编室管理员', 'YT领用审核员', 'YT频道管理员', 'YT排查盗版组员', 'Banner设计组员', 'YT频道设置组员']),
    },
    {
      name: 'Facebook',
      key: 'facebook',
      auth: can(['超级管理员', '研发管理员', '总编室管理员', 'FB领用审核员', 'FB主页管理员', 'FB排查盗版组员', 'Banner设计组员', 'FB频道设置组员']),
    },
  ])

  active.value = tabsConfig.find(x => !!x.auth)?.key || 'youtube'

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'applicationNo',
      placeholder: '申请单编号',
      label: '申请单编号',
    },
    {
      element: 'VTextField',
      props: 'contentJson',
      placeholder: '签约频道/作品名',
      label: '签约频道/作品名',
    },
    {
      element: 'VAutocomplete',
      props: 'status',
      placeholder: '申请单状态',
      label: '申请单状态',
      items: registerStatusOptions,
      multiple: true,
      initValue: queryData.status,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'VAutocomplete',
      props: 'registerType',
      placeholder: '申请类型',
      label: '申请类型',
      items: registerTypeOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      initValue: queryData.registerType,
    },
    {
      element: 'VAutocomplete',
      props: 'operationMode',
      placeholder: '服务类型',
      label: '服务类型',
      items: operationModeOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'DateRangePickers',
      props: 'createAt',
      searchKey: ['startDate', 'endDate'],
      initValue: [null, null],
      placeholder: '创建时间',
      label: '创建时间',
      clearable: true,
      range: true,
    },
    {
      element: 'VTextField',
      props: 'registerChannelName',
      placeholder: '关联频道/主页',
      label: '关联频道/主页',
    },
    {
      element: 'VAutocomplete',
      props: 'operationGroupId',
      placeholder: '组别',
      label: '组别',
      items: groupListOptions.value,
      multiple: false,
      itemText: 'nameVo',
      itemValue: 'deptId',
    },
    {
      element: 'VAutocomplete',
      props: 'operationLeaderId',
      placeholder: '组长',
      label: '组长',
      items: userListOptions.value,
      multiple: false,
      itemText: 'name',
      itemValue: 'userId',
    },
    {
      element: 'VAutocomplete',
      props: 'operatingMemberId',
      placeholder: '组员',
      label: '组员',
      items: userListOptions.value,
      multiple: false,
      itemText: 'name',
      itemValue: 'userId',
    },
    {
      element: 'VAutocomplete',
      props: 'applicantId',
      placeholder: '领用人',
      label: '领用人',
      items: applicantOptions.value,
      multiple: false,
      itemText: 'name',
      itemValue: 'userId',
    },
    {
      element: 'VAutocomplete',
      props: 'backendSettingFlag',
      placeholder: '频道后台设置',
      label: '频道后台设置',
      items: trueOrFalseOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      initValue: queryData.backendSettingFlag,
    },
    {
      element: 'VAutocomplete',
      props: 'bannerDesignFlag',
      placeholder: 'Banner设计',
      label: 'Banner设计',
      items: trueOrFalseOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      initValue: queryData.bannerDesignFlag,
    },
  ])

  const formatterJsonArr = json => {
    if (!json) return []
    const result = JSON.parse(json)

    return result instanceof Array ? result : []
  }

  const platformList = reactive({
    youtube: 0,
    facebook: 1,
  })

  const fetchList = async () => {
    loading.value = true
    const {
      data: {
        data, status, message: messageText,
      },
    } = await getPage({
      page: page.value,
      pageSize: pageSize.value,
      ...queryData,
      authorizePlatform: active.value === 'youtube' ? 0 : 1,
    })
    loading.value = false

    if (status === 200) {
      pageList.value = (data.contentApplicationVoList || []).map(item => ({
        ...item.contentApplicationDetailVo,
        ...item,
        channel: item.contentApplicationDetailVo && (formatterJsonArr(item.contentApplicationDetailVo.contentJson)?.find(x => x.isMainChannel) || formatterJsonArr(item.contentApplicationDetailVo.contentJson)[0] || {}),
        channels: item.contentApplicationDetailVo && formatterJsonArr(item.contentApplicationDetailVo.contentJson),
      }))
      pageLength.value = data.total
    } else {
      message.error(messageText)
    }
  }

  // 获取用户
  const fetchUserList = async deptId => {
    try {
      const {
        data: {
          data = [], status, message: messageText,
        },
      } = await getUserByDeptTree({
        authorizePlatform: platformList[active.value],
        ...deptId,
      })

      if (status === 200) {
        userListOptions.value = data
        if (!deptId) {
          applicantOptions.value = data
        }
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err.response.data.message)
    }
  }

  // 获取组别
  const fetchGroupList = async () => {
    try {
      const {
        data: {
          data = [], status, message: messageText,
        },
      } = await getDepartmentTree({
        authorizePlatform: platformList[active.value],
      })
      if (status === 200) {
        groupListOptions.value = data
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err.response.data.message)
    }
  }

  const search = async params => {
    Object.assign(queryData, params)
    page.value = 1
    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  const handleTabChange = tabItem => {
    active.value = tabItem.key
    page.value = 1
    Object.assign(queryData, queryDataOri)

    searchRef.value.reset()
    fetchGroupList()
    fetchUserList()
  }
  const changeSearch = val => {
    console.log(val)
    if (val.key === 'operationGroupId') {
      // queryData.applicantId = null 领用人
      queryData.operatingMemberId = null
      queryData.operationLeaderId = null
      if (!val.value || val.value === '') {
        fetchUserList()
      }
      if (val.value && val.value.length > 0) {
        fetchUserList({ deptId: val.value })
      }
    }
  }

  onMounted(() => {
    fetchList()
    fetchGroupList()
    fetchUserList()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    active,
    searchRef,
    tabsConfig,

    search,
    fetchList,
    handleRefresh,
    handleSizeChange,
    handleCurrentChange,
    handleTabChange,

    platformList,
    changeSearch,
  }
}
