<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    tableColumns: {
      type: Array,
      default: () => [
        {
          text: '行数',
          value: 'row',
          minWidth: 40,
        },
        {
          text: '失败原因',
          value: 'errorMsg',
          sortable: false,
          minWidth: 250,
        },
      ],
    },
  },
  setup(props, { emit }) {
    const handleSubmit = async () => {
      window.open(props.url)
    }
    const handleClose = () => {
      emit('update:visible')
      emit('close')
    }

    return {
      handleSubmit,
      handleClose,
    }
  },
})
</script>

<template>
  <vl-dialog style="z-index: 1003" :visible="visible" title="导入失败" width="450px" to="300px" @close="handleClose">
    <div class="label">导入失败，请修改后重新导入<span class="subLabel">（最多展示5条）</span></div>
    <div class="mt-3">
      <div class="addLine" />
      <XwTable border :columns="tableColumns" height="auto" :rows="list" />
    </div>

    <template #footer>
      <div class="flex-right gap8">
        <v-btn outlined @click="handleSubmit"> 下载失败文件 </v-btn>
        <v-btn color="primary" @click="handleClose"> 我知道了 </v-btn>
      </div>
    </template>
  </vl-dialog>
</template>

<style lang="scss" scoped>
.label {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);

  .subLabel {
    color: #00000080;
  }
}

.addLine {
  height: 1px;
  width: 100%;
  background-color: #e8eaec;
}
</style>
