<script>
export default {
  name: 'PlatformIcon',
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    showText: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    platform: {
      get() {
        return this.value
      },
      set() {},
    },
  },
}
</script>

<template>
  <div class="platform-icon__wrapper">
    <img v-if="platform === '快手'" src="@/assets/icon/ks.png" class="platform-icon" alt="">
    <img v-if="platform === '抖音'" src="@/assets/icon/dy.png" class="platform-icon" alt="">
    <img v-if="platform === 'B站'" src="@/assets/icon/b.png" class="platform-icon" alt="">
    <img v-if="platform === '好看'" src="@/assets/icon/hk.png" class="platform-icon" alt="">
    <img v-if="platform === '西瓜'" src="@/assets/icon/xg.png" class="platform-icon" alt="">
    <img v-if="platform === '小红书'" src="@/assets/icon/xhs.png" class="platform-icon" alt="">
    <img v-if="platform === 'YouTube'" src="@/assets/icon/YouTube.png" class="platform-icon" alt="">
    <img v-if="platform === 'Facebook'" src="@/assets/icon/Facebook.png" class="platform-icon" alt="">
    <img v-if="platform === 'TikTok' || platform === 'Tiktok'" src="@/assets/icon/dy.png" class="platform-icon" alt="">
    <img v-if="platform === '优酷'" src="@/assets/icon/yk.png" class="platform-icon" alt="">
    <img v-if="platform === '今日头条'" src="@/assets/icon/jrtt.png" class="platform-icon" alt="">
    <img v-if="platform === '微博'" src="@/assets/icon/wb.png" class="platform-icon" alt="">
    <img v-if="platform === '腾讯'" src="@/assets/icon/tx.png" class="platform-icon" alt="">
    <img v-if="platform === '爱奇艺'" src="@/assets/icon/aqy.png" class="platform-icon" alt="">
    <span v-if="showText" style="flex-shrink: 0">{{ platform || '-' }}</span>
    <span v-if="text" style="flex-shrink: 0" class="customize">{{ text }}</span>
  </div>
</template>

<style scoped lang="scss">
.platform-icon__wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;

  .platform-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
</style>
