<script>
import { ref, watch } from '@vue/composition-api'
import { useValidation } from '@/hooks/useValidation'
import { debounce } from '@prettyy/utils'
import { useMessage } from '@/hooks/useMessage'
import AppTooltip from '@/components/AppTooltip.vue'

export default {
  name: 'AutocompleteRemote',
  components: { AppTooltip },
  props: {
    allowRepetition: Boolean,
    defaultOptions: {
      type: Object,
      default: () => ({
        text: 'channelName',
        value: 'id',
      }),
    },
    api: {
      type: Function,
      default: () => () => {},
    },
    searchKey: {
      type: String,
      default: 'channelName',
    },
    selectList: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const search = ref('')
    const register = ref(false)
    const { required } = useValidation()
    const { message } = useMessage()
    const list = ref([])
    const newValue = ref(null)

    function getSignChannelList(e) {
      if (list.value.find(item => item.text === e)) return
      list.value = []
      props.api({ [props.searchKey]: e }).then(({ data }) => {
        if (data.status === 200) {
          const arrList = (data.data || []).map(item => ({
            text: item[props.defaultOptions.text],
            value: item[props.defaultOptions.value],
            cpName: item.copyrightHolder,
            oldData: item,
            ...item,
          }))
          list.value = props.allowRepetition ? [...arrList] : [...arrList].filter(item => !props.selectList.some(value => value.keyId === item[props.defaultOptions.value]))

          if (list.value.length === 0) {
            message.warning('暂未查询到相关内容')
          }
        }
      })
    }

    const searchQuery = debounce(800, getSignChannelList)

    watch(search, val => val && searchQuery(val))

    function valueChange(e) {
      const data = list.value.find(item => item.value === e)
      newValue.value = e
      if (data) {
        emit('input', e)
        emit('change', e, data)
      }
    }

    return {
      search,
      register,
      required,
      list,
      newValue,
      valueChange,
    }
  },
}
</script>

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="newValue"
    :loading="register"
    :items="list"
    :search-input.sync="search"
    placeholder="请输入后选择"
    dense
    hide-no-data
    hide-details
    outlined
    clearable
    @change="valueChange"
  >
    <template #prepend-item>
      <div style="position: sticky; top: 0; background-color: #ffffff; z-index: 99999">
        <div class="flex-left px15 py12" style="width: 390px; background-color: #ffffff;">
          <div style="width: 190px; font-weight: bold" class="fs14">
            频道名称
          </div>
          <div style="width: 170px; font-weight: bold" class="fs14">
            CP名称
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </template>
    <template #item="{item}">
      <template v-if="typeof item !== 'object'">
        <v-list-item-content v-text="item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content style="line-height: 20px">
          <div class="flex-left" style="width: 360px">
            <div style="width: 190px;" class="fs14">
              <AppTooltip :label="item.text || '-'" height="28" width="190" />
            </div>
            <div style="width: 170px; height: 28px" class="fs14">
              <AppTooltip :label="item.copyrightHolder || '-'" height="28" width="170" />
            </div>
          </div>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<style scoped lang="scss">
::v-deep .v-list-item {
  height: 38px !important;
}
</style>
