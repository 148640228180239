<script>
import {
  onMounted, ref, reactive, nextTick, watch,
} from '@vue/composition-api'
import { VPopover } from 'v-tooltip'

export default {
  name: 'OverflowTooltip',
  components: { VPopover },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
    isLink: Boolean,
    color: {
      type: String,
      default: '',
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    width: {
      type: [Number, String],
      default: 0,
    },
    offsetWidth: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    click: {
      type: Function,
      validate: value => typeof value === 'function',
    },
  },
  setup(props) {
    const linkRef = ref()
    const isPopover = ref(false)
    function openLink() {
      if (props.click) {
        props.click()

        return false
      }
      if (!props.isLink || !props.link) return false
      window.open(props.link, props.target)

      return true
    }
    const ellipsis = reactive({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
      lineHeight: 'inherit',
    })

    const style = ref({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '10px',
      height: '1px',
    })

    function init() {
      const parentElement = linkRef.value?.parentNode
      if (!parentElement) return false
      const { width, height } = parentElement.getBoundingClientRect()
      ellipsis.width = `${(width || props.width) - 10}px`
      ellipsis.height = `${(height || props.height) - 4}px`
      ellipsis.lineHeight = `${(height || props.height)}px`
      if (props.color) {
        ellipsis.color = `${props.color} !important`
      }
      const computedStyle = window.getComputedStyle(parentElement)

      style.value = ellipsis
      const dicStyle = {
        height: '1px',
        fontSize: computedStyle.fontSize,
        lineHeight: 'inherit',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        color: 'transparent',
        display: 'inline-block',
      }

      // 创建计算节点
      const element = document.createElement('div')
      const keys = Object.keys(dicStyle)
      keys.forEach(key => {
        element.style[key] = dicStyle[key]
      })
      element.innerText = props.label || props.link
      document.body.appendChild(element)

      // 子节点宽度如果大于父节点宽度，则显示弹出层
      if (element.scrollWidth > ((width || props.width) - 10 - props.offsetWidth)) {
        isPopover.value = true
        style.value.width = `${(width || props.width) - props.offsetWidth}px`
      } else {
        style.value.width = `${element.scrollWidth}px`
      }

      // 移除计算节点
      document.body.removeChild(element)
    }

    watch(() => props.label, () => {
      init()
    })

    onMounted(() => {
      nextTick(() => {
        init()
      })
    })

    return {
      openLink,
      linkRef,
      style,
      isPopover,
    }
  },
}
</script>
<template>
  <div v-if="!isPopover" ref="linkRef" :style="style" class="flex-left">
    <div
      class="text-truncate"
      :class="{ 'app-link': isLink }"
      @click="openLink"
    >
      <slot name="label">
        {{ label || link }}
      </slot>
    </div>
    <slot name="append" />
  </div>
  <v-popover
    v-else
    :html="false"
    placement="bottom"
    popover-class="popover-content"
    trigger="hover"
    container="main"
  >
    <div
      class="flex-left"
      :class="{ 'app-link': isLink }"
      :style="style"
    >
      <div class="text-truncate pointer" @click.stop="openLink">
        {{ label || link }}
      </div>
      <slot name="append" />
    </div>
    <template #popover>
      <slot name="content">
        <span :style="{ color: color }">
          {{ label || link }}
        </span>
      </slot>
    </template>
  </v-popover>
</template>

<style lang='scss' scoped>
.app-link {
  color: #2381FF !important;
  cursor: pointer;
  display: inline-block;
}
</style>
