const tableCols = [
  {
    text: '频道名称',
    sortable: false,
    value: 'channelName',
    width: 200,
    slots: 'channelName',
    fixed: 'left',
  },
  {
    text: '来源平台',
    sortable: false,
    value: 'platform',
    width: 100,
    slots: 'platform',
  },
  {
    text: 'CP名称',
    sortable: false,
    value: 'copyrightHolder',
    width: 150,
  },
  {
    text: '垂类',
    sortable: false,
    value: 'categoryStr',
    width: 100,
  },
  {
    text: '授权平台',
    sortable: false,
    value: 'authorizePlatformName',
    width: 150,
  },
  {
    text: '授权期限',
    sortable: false,
    value: 'authorizePeriod',
    width: 200,
    slots: 'authorizePeriod',
  },
  {
    text: '服务类型',
    sortable: false,
    value: 'operationModeMultiple',
    width: 120,
  },
  { text: '套餐名称', value: 'servicePageName', minWidth: 150 },
  {
    text: '运营方案',
    sortable: false,
    value: 'deliveryReceiptNum',
    width: 130,
    slots: 'deliveryReceiptNum',
  },
  {
    text: '合约状态',
    sortable: false,
    value: 'contractStatus',
    width: 100,
    slots: 'contractStatus',
  },
  {
    text: '当前通道',
    sortable: false,
    value: 'currentChannel',
    slots: 'ways',
    width: 150,
  },
  {
    text: '视频数',
    sortable: false,
    value: 'videoCount',
    width: 120,
    align: 'right',
    formatter: ({ cellValue }) => (cellValue || cellValue === 0 ? cellValue.toLocaleString() : '-'),
  },
  {
    text: '时长(小时)',
    sortable: false,
    value: 'videoDuration',
    width: 120,
    align: 'right',
    formatter: ({ cellValue }) => (cellValue || cellValue === 0 ? (cellValue / 3600)?.toFixed(2)?.toLocaleString() : '-'),
  },
  {
    text: '创建时间',
    sortable: false,
    value: 'createdAt',
    width: 150,
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    width: 150,
    fixed: 'right',
  },
]

export default tableCols
