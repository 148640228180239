<script>
import {
  defineComponent, computed, reactive, ref, onMounted, watch,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@core/utils/validation'
import {
  domPlatformOptions, platformOptionsByOverseas,
} from '@core/utils/options'
import {
  saveWays, getSignChannel, getRegisterChannel, getSignWorks,
} from '../api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: 'channel',
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formData = reactive({
      signPlatform: null,
      signWorks: null,
      signChannel: null,
      signUrl: null,
      platform: 0, // 默认youtube
      channelId: null,
      videoTags: null,
      playList: null,
    })
    const formRef = ref()
    const showDialog = computed(() => props.value)
    const signChannelList = ref([])
    const registerChannelList = ref([])
    const signWorksList = ref([])
    const isLoading = ref(false)

    const signChannelName = computed(() => signChannelList.value.find(x => x.id === formData.signChannel)?.channelName || null)
    const registerChannelName = computed(() => registerChannelList.value.find(x => x.id === formData.channelId)?.channelName || null)
    const signWorksName = computed(() => signWorksList.value.find(x => x.id === formData.signWorks)?.name || null)

    const videoTags = computed(() => `${registerChannelName.value || ''}${registerChannelName.value ? '-' : ''}${signChannelName.value || signWorksName.value || ''}`)
    const playList = computed(() => `${signChannelName.value || signWorksName.value || ''}`)

    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (!valid) return
      try {
        const { data: { status, message: messageText } } = await saveWays({
          signPlatform: formData.signPlatform,
          signUrl: formData.signUrl,
          signChannelId: props.active === 'channel' ? formData.signChannel : formData.signWorks,
          signChannelName: props.active === 'channel' ? signChannelName.value : signWorksName.value,
          registerChannelName: registerChannelName.value,
          registerPlatform: formData.platform,
          registerChannelId: formData.channelId,
          videoCustomTag: videoTags.value,
          playlist: playList.value,
          type: props.active === 'channel' ? 0 : 1, // 0频道 1作品
        })
        if (status === 200) {
          message.success('提交成功')
          emit('success')
          handleClose()
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    const fetchSignChannelList = async () => {
      try {
        const { data: { data = [], status, message: messageText } } = await getSignChannel({
          platform: formData.signPlatform,
        })
        if (status === 200) {
          signChannelList.value = data
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    const fetchRegisterChannelList = async () => {
      isLoading.value = true
      try {
        const { data: { data = [], status, message: messageText } } = await getRegisterChannel({
          type: formData.platform,
        })
        if (status === 200) {
          registerChannelList.value = data.map(x => ({ ...x, showName: `${x.channelName}(${x.id})` }))
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
      isLoading.value = false
    }

    const fetchSignWorks = async () => {
      try {
        const { data: { data = [], status, message: messageText } } = await getSignWorks({
          platform: formData.signPlatform,
        })
        if (status === 200) {
          signWorksList.value = data
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    const isChannel = computed(() => props.active === 'channel')

    // 跳转
    const handleLink = url => {
      if (url) {
        window.open(url)
      }
    }

    watch(() => formData.signPlatform, fetchSignChannelList)
    watch(() => formData.platform, fetchRegisterChannelList)

    const handleChannelChange = val => {
      formData.signUrl = signChannelList.value.find(item => item.id === val)?.link || ''
    }

    const handleWorksChange = val => {
      formData.signUrl = signWorksList.value.find(item => item.id === val)?.link || ''
    }

    // 平台改变
    const handleRegisterPlatformChange = () => {
      formData.channelId = null
    }

    const handleSignPlatformChange = () => {
      formData.signChannel = null
      formData.signUrl = null
    }

    onMounted(() => {
      fetchSignChannelList()
      fetchRegisterChannelList()
      fetchSignWorks()
    })

    return {
      formData,
      showDialog,
      formRef,
      required,
      isChannel,
      domPlatformOptions,
      platformOptionsByOverseas,
      signChannelList,
      registerChannelList,
      videoTags,
      playList,
      signWorksList,
      isLoading,
      handleChannelChange,
      handleWorksChange,

      handleClose,
      handleLink,
      handleSubmit,
      handleRegisterPlatformChange,
      handleSignPlatformChange,

    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="690"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        新增
      </h3>
      <v-form
        ref="formRef"
        class="form-outer"
      >
        <!--form-inner-->
        <div class="form-inner">
          <div
            v-if="isChannel"
            class="form-item"
          >
            <label>签约内容平台</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.signPlatform"
                :rules="[required]"
                :items="domPlatformOptions"
                no-data-text="暂无数据"
                hide-details
                placeholder="签约内容平台"
                dense
                outlined
                clearable
                item-text="label"
                item-value="value"
                style="width: 200px"
                @change="handleSignPlatformChange"
              ></v-autocomplete>
            </div>
          </div>
          <div
            v-if="!isChannel"
            class="form-item"
          >
            <label>签约内容作品</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.signWorks"
                :rules="[required]"
                :items="signWorksList"
                no-data-text="暂无数据"
                hide-details
                placeholder="签约内容作品"
                dense
                outlined
                clearable
                item-text="name"
                item-value="id"
                style="width: 200px"
                @change="handleWorksChange"
              ></v-autocomplete>
            </div>
          </div>
          <div
            v-if="isChannel"
            class="form-item"
          >
            <label>签约内容频道</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.signChannel"
                :rules="[required]"
                :items="signChannelList"
                no-data-text="暂无数据"
                hide-details
                placeholder="签约内容频道"
                dense
                outlined
                clearable
                item-text="channelName"
                item-value="id"
                style="width: 200px"
                @change="handleChannelChange"
              ></v-autocomplete>
            </div>
          </div>
          <div
            class="form-item"
          >
            <label>签约内容链接</label>
            <div>
              <v-text-field
                v-model="formData.signUrl"
                dense
                hide-details
                outlined
                readonly
                placeholder="签约内容链接"
                single-line
                style="width: 200px"
                @click="handleLink(formData.signUrl)"
              ></v-text-field>
            </div>
          </div>
        </div>
        <!--form-inner-->
        <div class="form-inner">
          <div
            class="form-item"
          >
            <div class="form-arro"></div>
            <label>注册平台</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.platform"
                :rules="[required]"
                :items="platformOptionsByOverseas"
                no-data-text="暂无数据"
                hide-details
                placeholder="注册平台"
                dense
                outlined
                clearable
                item-text="label"
                item-value="value"
                style="width: 200px"
                :disabled="isLoading"
                @change="handleRegisterPlatformChange"
              ></v-autocomplete>
            </div>
          </div>
          <div
            class="form-item"
          >
            <div
              v-if="isChannel"
              class="form-arro"
            ></div>
            <label>注册{{ formData.platform === 1 ? '主页' : '频道' }}</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.channelId"
                :rules="[required]"
                :items="registerChannelList"
                no-data-text="暂无数据"
                hide-details
                :placeholder="`注册${ formData.platform === 1 ? '主页' : '频道' }`"
                dense
                outlined
                clearable
                item-text="showName"
                item-value="id"
                style="width: 200px"
              ></v-autocomplete>
            </div>
          </div>
          <div
            v-if="formData.platform === 1"
            class="form-item"
          >
            <label>视频自定义标签</label>
            <div>
              <v-text-field
                v-model="videoTags"
                dense
                hide-details
                outlined
                disabled
                placeholder="视频自定义标签"
                single-line
                style="width: 200px"
              ></v-text-field>
            </div>
          </div>
          <div
            v-if="formData.platform === 0"
            class="form-item"
          >
            <label>播放列表</label>
            <div>
              <v-text-field
                v-model="playList"
                dense
                hide-details
                outlined
                disabled
                placeholder="播放列表"
                single-line
                style="width: 200px"
              ></v-text-field>
            </div>
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 16px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .form-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    flex-shrink: 0;
  }
  .form-outer{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    padding: 24px 0;
    .form-inner{
      padding-left: 20px;
    }
  }
  .form-item{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    position: relative;
    label{
    display: block;
    width: 114px;
    text-align: right;
    height: 34px;
    font-size: 14px;
    line-height: 34px;
    padding-right: 12px;
    color: rgba(0, 0, 0, 0.85);
    span{
      color: rgba(0, 0, 0, 0.25)
    }
  }
  }
  .form-arro{
    position: absolute;
    left: -8px;
    top: -2px;
    width: 20px;
    height: 14px;
    margin-top: 12px;
    margin-bottom: 36px;
    margin-left: 12px;
    display: block;
    background: url('../../../../assets/images/icons/arro_rt.png');
  }
}
</style>
