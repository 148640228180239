<script>
import { onMounted, ref } from '@vue/composition-api'
import IconPlatform from '@/components/IconPlatform.vue'
import { useMessage } from '@/hooks/useMessage'
import { removeWays, removeList } from '../api'

export default {
  name: 'Delete',
  components: { IconPlatform },
  props: {
    deleteData: {
      type: Array,
      default: () => ([]),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const visible = ref(true)
    const [start, end] = props.deleteData
    const { message } = useMessage()
    const roadItemRef = ref()
    const roadHeight = ref(52)

    function submit() {
      removeList(props.deleteData.map(item => item.id)).then(({ data }) => {
        if (data.status === 200) {
          message.success('删除成功')
          visible.value = false
          emit('refresh')
        } else {
          message.error(data.message)
        }
      }).catch(error => {
        message.error(error.response.data.message)
      })
    }

    onMounted(() => {
      roadHeight.value = roadItemRef.value.clientHeight
    })

    return {
      visible,
      start,
      end,
      submit,
      roadItemRef,
      roadHeight,
    }
  },
}
</script>

<template>
  <vl-dialog
    :visible.sync="visible"
    :title="row.title"
    width="450px"
    top="0"
    @close="$emit('close')"
  >
    <div class="flex-vertical">
      <div class="topTips fs14 gap4">
        <v-icon color="#F24545" size="18" class="pt2">
          mdi-alert-circle
        </v-icon>
        <div>
          共选中 <span class="primary--text">{{ deleteData.length }}</span> 条通道，
          发布通道删除后不可恢复，请谨慎操作，是否确认删除？
        </div>
      </div>
      <div class="delete-body">
        <div class="flex-between mt12 fs14 gap8">
          <div ref="roadItemRef" class="road-item left gap8">
            <div v-for="item,index in deleteData.slice(0,10)" :key="index" class="flex-left gap4">
              <IconPlatform :platform="item.platform || ''" />
              <span class="line-clamp1">{{ item.name || '' }}</span>
            </div>
          </div>
          <img src="@/assets/images/ways/road.png" height="20" alt="road" />
          <div class="road-item flex-left gap4" :style="{height: roadHeight + 'px'}">
            <IconPlatform :platform=" row.platform || ''" />
            <span class="line-clamp1">{{ row.name || '' }}</span>
          </div>
        </div>
        <div v-if="deleteData.length > 10" class="fs14 mt6" style="color: rgba(0,0,0,0.5);">
          最多展示 10 条
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex-right gap8">
        <v-btn outlined @click="visible = false">
          取消
        </v-btn>
        <v-btn color="primary" @click="submit">
          确认
        </v-btn>
      </div>
    </template>
  </vl-dialog>
</template>

<style scoped lang="scss">

.topTips{
  display: flex;
  align-items: flex-start;
  color: rgba(0,0,0,0.85);
}
.road-item {
  padding: 12px 12px;
  flex: auto;
  width: 100%;
  min-height: 52px;
  background: #F6F7F8;
  border-radius: 6px;
  color: rgba(0,0,0,0.8);
}
.left{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vl-dialog__wrapper {
  display: flex;
  align-items: center;
}
</style>
