<script>
import {
  defineComponent, computed, reactive, ref, onMounted, watch,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@core/utils/validation'
import {
  // eslint-disable-next-line import/named
  fieldOptions, operationGroupOptionsByYtnews, operationGroupOptionsByFbnews, levelOptionsnews, channelStates,
  contentClass, homepageProperies, homepageStatus,
} from '@core/utils/options'
import {
  channelBatchConfig,
} from '@/api/accountHomePageAdd'
import VCascaderVue from '@core/components/search-form/VCascader.vue'

export default defineComponent({
  components: {
    VCascaderVue,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: 'channel',
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    searchParms: {
      type: Object,
      default: () => ({}),
    },
    batchsetTit: {
      type: String,
      default: '全部设置',
    },
    showEdit: {
      type: [Boolean, String],
      default: true,
    },
    checkedObj: {
      type: Array,
      default: () => ([]),
    },
    categoryList: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const formData = reactive({
      channelId: null,
      ids: null,
      category: null,
      categoryId: null,
      parentCategoryId: null,
      operationTeam: null,
      collectionType: 1,
      profit: null,
      operateLevel: null,
      contentType: null, // facebook
      pageAttribute: null,
    })
    const accountNames = ref('')
    const formRef = ref()
    const showDialog = computed(() => props.value)
    const tabDes = props.active === 'youtube' ? '频道名称' : '账号名称'
    const categoryObj = ref({})

    const changeList = ref([])
    const changeKey = ref('')
    const keyValue = ref('')
    const changeOption = ref(
      {
        operationTeam: operationGroupOptionsByYtnews,
        collectionType: [
          {
            text: '单开',
            value: 1,
          },
          {
            text: '合集',
            value: 0,
          },
        ],
        profit: [
          {
            text: '是',
            value: 1,
          },
          {
            text: '否',
            value: 0,
          },
        ],
        category: props.categoryList,
        contentType: contentClass,
        pageAttribute: homepageProperies,
      },
    )
    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const handleCategoryChange = (value, nodes = []) => {
      const [item] = nodes
      categoryObj.value = item
    }

    const isChannel = computed(() => props.active === 'youtube')

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (!valid) return
      try {
        const newparms = {}
        if (changeKey.value === 'profit') {
          const vals = keyValue.value ? 1 : 0
          newparms[changeKey.value] = vals
        } else {
          newparms[changeKey.value] = keyValue.value
        }

        const allParms = {
          batchKey: changeKey.value,
          batchValue: newparms[changeKey.value].toString(),
          type: isChannel.value ? 0 : 1,
          ...props.searchParms,
        }
        const batchParms = {
          ids: formData.ids,
          ...newparms,
          type: isChannel.value ? 0 : 1, // 0 youTube 1 facebook
        }
        let parms = {}
        if (props.batchsetTit === '全部设置') {
          parms = allParms
        } else {
          parms = batchParms
        }

        if (changeKey.value === 'category') {
          parms.categoryId = categoryObj.value.id
          parms.parentCategoryId = categoryObj.value.parentId
        }
        console.log('parms', parms)
        const { data: { status, message: messageText } } = await channelBatchConfig({
          ...parms,
        })
        if (status === 200) {
          message.success('提交成功')
          emit('success')
          handleClose()
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    watch(changeKey, () => {
      keyValue.value = ''
    })

    onMounted(() => {
      // isChannel.value ? 0 : 1, // 0 youTube 1 facebook

      if (isChannel.value) {
        changeOption.value.operationTeam = operationGroupOptionsByYtnews
        changeList.value = [
          {
            text: '运营团队',
            value: 'operationTeam',
          },
          {
            text: '单开/合集',
            value: 'collectionType',
          },
          {
            text: '获利',
            value: 'profit',
          },
          {
            text: '垂类',
            value: 'category',
          },
        ]
      } else {
        changeOption.value.operationTeam = operationGroupOptionsByFbnews
        changeList.value = [
          {
            text: '垂类',
            value: 'category',
          },
          {
            text: '内容分类',
            value: 'contentType',
          },
          {
            text: '主页属性',
            value: 'pageAttribute',
          },
          {
            text: '运营团队',
            value: 'operationTeam',
          },
          {
            text: '单开/合集',
            value: 'collectionType',
          },
          {
            text: '获利',
            value: 'profit',
          },
        ]
      }

      if (props.batchsetTit !== '全部设置') {
        const idsData = props.checkedObj.map(item => item.id)
        accountNames.value = props.checkedObj.map(item => item.channelName).join(',')
        formData.ids = idsData
      }
    })


    return {
      accountNames,
      tabDes,
      formData,
      showDialog,
      formRef,
      required,
      isChannel,
      handleCategoryChange,

      handleClose,
      handleSubmit,

      // 下拉
      fieldOptions,
      operationGroupOptionsByYtnews,
      operationGroupOptionsByFbnews,
      levelOptionsnews,
      channelStates,
      contentClass,
      homepageProperies,
      homepageStatus,

      changeList,
      changeKey,
      changeOption,
      keyValue,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="490"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        {{ batchsetTit }}
      </h3>
      <v-form
        ref="formRef"
        class="form-outer"
      >
        <!--form-inner-->
        <div class="form-inner">
          <div
            class="form-item"
          >
            <label v-if="batchsetTit!='全部设置'">{{ tabDes }}</label>
            <div>
              <v-tooltip right>
                <template
                  #activator="{ on, attrs }"
                >
                  <span
                    v-bind="attrs"
                    class="text--link pl10 edit-des"
                    v-on="on"
                  >{{ accountNames }}</span>
                </template>
                <div>
                  <div>
                    {{ accountNames }}
                  </div>
                </div>
              </v-tooltip>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>字段名</label>
            <div>
              <v-autocomplete
                v-model.trim="changeKey"
                :rules="[required]"
                :items="changeList"
                no-data-text="暂无数据"
                hide-details
                placeholder="选择"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 300px"
              ></v-autocomplete>
            </div>
          </div>
          <div
            class="form-item"
          >
            <label>字段值</label>
            <div>
              <v-autocomplete
                v-if="changeKey !== 'category'"
                v-model.trim="keyValue"
                :rules="[required]"
                :items="changeOption[changeKey]"
                no-data-text="暂无数据"
                hide-details
                placeholder="选择"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 300px"
              ></v-autocomplete>
              <VCascaderVue
                v-if="changeKey === 'category'"
                v-model.trim="keyValue"

                :items="categoryList"
                no-data-text="暂无数据"
                hide-details
                placeholder="垂类"
                dense
                outlined
                clearable
                item-text="name"
                item-value="id"
                style="width: 250px"
                @change="handleCategoryChange"
              ></VCascaderVue>
            </div>
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 16px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .form-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    flex-shrink: 0;
  }
  .form-outer{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    padding: 24px 0;
    .form-inner{
      padding-left: 20px;
    }
  }
  .form-item{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    position: relative;
    label{
    display: block;
    width: 114px;
    text-align: right;
    height: 34px;
    font-size: 14px;
    line-height: 34px;
    padding-right: 12px;
    color: rgba(0, 0, 0, 0.85);
    span{
      color: rgba(0, 0, 0, 0.25)
    }
  }
  }
  .form-arro{
    position: absolute;
    left: -8px;
    top: -2px;
    width: 20px;
    height: 14px;
    margin-top: 12px;
    margin-bottom: 36px;
    margin-left: 12px;
    display: block;
    // background: url('../../../../../assets/images/icons/arro_rt.png');
    background: url('~@/assets/images/icons/arro_rt.png');
  }
}

.w200{
  width: 200px;
}
.edit-des{
  display: inline-block;
  padding-top: 5px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style lang="scss">
.singeRadio{
  .v-input--selection-controls {
    margin-top: 3px;
    padding-top: 4px;
  }
  .v-input__control {
    height: 32px;
  }
  .v-input--selection-controls__ripple {
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    position: absolute;
    transition: inherit;
    width: 18px;
    left: -7px;
    top: calc(50% - 16px);
    margin: 7px;
  }
  .v-input--selection-controls__input {
      color: inherit;
      display: inline-flex;
      flex: 0 0 auto;
      height: 18px;
      position: relative;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      transition-property: transform;
      width: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

}
.switch-box{
  .first-secondary{
    position: relative;
    left: 25px;
  }
  .second-secondary{
    position: relative;
    left: -40px;
  }
  .text--secondary {
    color: rgba(255, 255, 255, 1) !important;
  }

  #switch-des{
    color: rgba(255, 255, 255, 1) !important;
    z-index: 1;
    pointer-events: none;
    // z-index: 1;
  }
  .v-application.theme--light .v-input--switch:not(v-input--switch--flat):not(v-input--switch--inset) .v-input--switch__thumb {
    z-index: 22;
    box-shadow: 0 1px 3px 0 rgba(19, 17, 32, 1), 0 2px 1px -1px rgba(19, 17, 32,1), 0 1px 1px 0 rgba(19, 17, 32,1);
  }
  .v-input--switch__thumb {
    transform: translate(20px, 0) !important;
    z-index: 22;
  }
}

.v-tooltip__content{
  max-width:400px ;
  word-break: break-all;
  overflow-wrap: break-word;
}
</style>
