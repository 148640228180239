import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-view-tow',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex-vertical gap8",staticStyle:{"width":"100%"}},[_c('TabsPro',{on:{"tabClick":_vm.handleTabChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.tabsConfig),function(item){return _c('TabPanePro',{key:item.key,attrs:{"label":item.name,"name":item.key}})}),1),_c('XwSearch',{ref:"searchRef",attrs:{"col-span":3,"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1)]},proxy:true},{key:"main-header",fn:function(){return [_c('div',{staticClass:"flex-left gap8"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleAdd(0)}}},[_vm._v(" 新增YT ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleAdd(1)}}},[_vm._v(" 新增FB ")]),_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){return _vm.handleExport()}}},[_vm._v(" 导入 ")]),_c(VBtn,{attrs:{"outlined":""},on:{"click":_vm.batchDelete}},[_vm._v(" 批量删除 ")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('vl-pagination',{attrs:{"page-size":_vm.pageSize,"page-index":_vm.page,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})]},proxy:true}])},[_c('super-table',{ref:"tableRef",attrs:{"columns":_vm.tableColumns,"data":_vm.pageList,"loading":_vm.loading,"row-config":{ height: 65,keyField:'id' },"checkbox-config":{reserve:true}},on:{"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"signPlatform",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('icon-platform',{key:("signPlatform" + index),staticStyle:{"transform":"translateY(3px)"},attrs:{"platform":row.signPlatform}}),_c('span',{staticStyle:{"line-height":"16px"}},[_vm._v(_vm._s(row.signChannelName))])]}},{key:"playlist",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.registerPlatform !== 'YouTube' ? row.videoCustomTag || '-' : row.playlist || '-'))])]}},{key:"compositionType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getLabelText(row))+" ")]}},{key:"signUrl",fn:function(ref){
var row = ref.row;
return [(row.signUrl)?_c('a',{staticClass:"text--link overflow",staticStyle:{"text-decoration":"none"},attrs:{"href":row.signUrl,"target":"_blank"}},[_vm._v(_vm._s(row.signUrl))]):_c('span',[_vm._v("-")])]}},{key:"registerChannelName",fn:function(ref){
var row = ref.row;
return [_c('ToolsBox',{attrs:{"platform":row.registerPlatform,"name":row.registerChannelName,"channel-id":row.registerChannelId}})]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"app-link",on:{"click":function($event){return _vm.handleRemove(row)}}},[_vm._v(" 删除 ")])]}}])}),(_vm.visibleDelete)?_c('Delete',{attrs:{"delete-data":_vm.deleteData,"row":_vm.deleteItem},on:{"close":function($event){_vm.visibleDelete = false},"refresh":_vm.handleRefresh}}):_vm._e(),(_vm.visibleAdd)?_c('AddData',{attrs:{"platform":_vm.rowPlatform,"active-type":_vm.active === 'channel' ? 0 : 1},on:{"close":function($event){_vm.visibleAdd = false},"refresh":_vm.handleRefresh}}):_vm._e(),_c('ExportTemplate',{attrs:{"visible":_vm.ExportTemplateDialog,"active-type":_vm.active === 'channel' ? 0 : 1},on:{"close":function($event){_vm.ExportTemplateDialog = false},"submit":_vm.handleSubmitExportTemplateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }