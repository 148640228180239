<script>
import { computed, ref } from '@vue/composition-api'
import useAppConfig from '@/hooks/useAppConfig'
import { isNumber } from '@prettyy/utils'

export default {
  name: 'BasicDrawer',
  props: {
    title: {
      type: [String, Number],
      default: '基础抽屉',
    },
    subTitle: {
      type: [String, Number],
      default: '',
    },
    width: {
      type: [Number, String],
      default: 1200,
    },
    showExpand: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    beforeClose: {
      type: Function,
    },
  },
  setup(props, { emit }) {
    const visible = ref(true)
    const { smAndUp, menuIsVerticalNavMini } = useAppConfig()
    const isExpand = ref(false)
    const width = computed(() => {
      if (isNumber(props.width)) return `${props.width}px`
      if (props.width && !props.width.endsWith('px')) return `${props.width}px`

      return props.width
    })
    const expandWidth = computed(() => {
      if (!smAndUp.value) return '100%'
      if (props.showExpand && isExpand.value && !menuIsVerticalNavMini.value) return 'calc(100% - 245px)'
      if (props.showExpand && isExpand.value && menuIsVerticalNavMini.value) return 'calc(100% - 68px)'
      if (isExpand.value) return 'calc(100% - 68px)'

      if (props.width) {
        return width.value
      }

      return '1200px'
    })
    function close() {
      emit('close')
    }

    return {
      visible,
      smAndUp,
      expandWidth,
      isExpand,
      close,
    }
  },
}
</script>

<template>
  <vl-drawer
    :visible.sync="visible"
    :size="expandWidth"
    :before-close="beforeClose"
    :class="[showExpand ? 'basic-open': '']"
    @closed="close"
  >
    <template #title>
      <div class="basic-header" :style="{ width: showExpand ? 'calc(100% - 20px)': ''}">
        <div class="font-weight-semibold text-base text--primary basic-header__title">
          <div class="flex-left gap8">
            <slot name="icon" />
            <span class="fs20">{{ title }}</span>
          </div>
          <span v-if="subTitle" class="basic-header__sub-title">({{ subTitle }})</span>

          <slot name="header-append" />
        </div>

        <span v-if="smAndUp && showExpand" class="basic-header__open" @click="isExpand = !isExpand">
          <v-icon>
            {{ isExpand ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
          </v-icon>
        </span>
      </div>
    </template>

    <div class="basic-view">
      <div v-if="$slots['main-header']" class="basic-view-header flex-left gap12 px20 fs14">
        <slot name="main-header" />
      </div>
      <div class="basic-view-main">
        <slot />
      </div>
      <div v-if="$slots.footer" class="basic-view-footer flex-right px20 gap8">
        <slot name="footer" />
      </div>
    </div>
  </vl-drawer>
</template>

<style scoped lang="scss">
.basic {
  &-header {
    position: relative;

    &__title {
      display: flex;
      align-items: center;
      gap: 10px;
      line-height: normal !important;
    }

    &__sub-title {
      font-weight: 300;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.60);
      transform: translateY(-2px);
      flex-shrink: 0;
    }

    &__open {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      left: -18px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%) translateX(-50%);
      z-index: 9999;
      background: #F6F7F8FF;
      border-radius: 50%;
    }
  }

  &-view {
    flex: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #F6F7F8;
    overflow: hidden;
    overflow-y: auto;

    &-header {
      height: 50px;
      flex-shrink: 0;
      background-color: #ffffff;
    }

    &-main {
      flex: auto;
      overflow: hidden;
      overflow-y: auto;
    }

    &-footer {
      height: 60px;
      flex-shrink: 0;
      background-color: #ffffff;
    }
  }

  &-open {
    ::v-deep .vl-drawer {
      overflow: inherit;
    }
  }
}
</style>
