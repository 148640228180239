import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppView',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('XwTabs',{attrs:{"tabs":_vm.tabsConfig,"active":_vm.active},on:{"change":_vm.handleTabChange}}),_c('div',{staticStyle:{"padding":"12px 0"}},[_c('XwSearch',{ref:"searchRef",attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.handerSearch}})],1)],1)]},proxy:true},{key:"main-header",fn:function(){return [_c('div',{staticClass:"pb-2"},[_c(VBtn,{staticClass:"mr-3",attrs:{"outlined":""},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")]),_c(VBtn,{staticClass:"btn-item mr-3",attrs:{"outlined":""},on:{"click":_vm.handleBatchsetting}},[_vm._v(" "+_vm._s(_vm.batchsetTit)+" ")]),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.active=='youtube'),expression:"active=='youtube'"}],staticClass:"btn-item mr-3",attrs:{"outlined":""},on:{"click":_vm.handleXlsx}},[_c(VIcon,{attrs:{"left":"","blue":""}},[_vm._v(" mdi-cloud-upload ")]),_vm._v(" 导入获利状态 ")],1),_c(VBtn,{staticClass:"btn-item mr-3",attrs:{"outlined":""},on:{"click":_vm.handleExport}},[_vm._v(" 导出 ")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})]},proxy:true}])},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList,"row-config":{height: 76},"height":"auto"},on:{"selectChange":_vm.selectChange,"clearCheckboxRow":_vm.clearCheckboxRow},scopedSlots:_vm._u([{key:"ways",fn:function(ref){
var row = ref.row;
return [(row.currentChannels && row.currentChannels.length)?_c('div',{staticClass:"current-channel"},[_c('div',_vm._l((row.currentChannels),function(item,idx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(idx < 3),expression:"idx < 3"}],key:idx},[_vm._v(" "+_vm._s(item)+" ")])}),0),(row.currentChannels.length > 3)?_c('div',{staticClass:"more"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--link pl10"},'span',attrs,false),on),[_vm._v("更多")])]}}],null,true)},[_c('div',_vm._l((row.currentChannels),function(item,idx){return _c('div',{key:idx},[_vm._v(" "+_vm._s(item)+" ")])}),0)])],1):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"copyrightHolders",fn:function(ref){
var row = ref.row;
return [(row.copyrightHolders && row.copyrightHolders.length)?_c('div',{staticClass:"current-channel"},[_c('div',_vm._l((row.copyrightHolders),function(item,idx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(idx < 3),expression:"idx < 3"}],key:idx},[_vm._v(" "+_vm._s(item)+" ")])}),0),(row.copyrightHolders.length > 3)?_c('div',{staticClass:"more"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--link pl10"},'span',attrs,false),on),[_vm._v("更多")])]}}],null,true)},[_c('div',_vm._l((row.copyrightHolders),function(item,idx){return _c('div',{key:idx},[_vm._v(" "+_vm._s(item)+" ")])}),0)])],1):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"isLinked",fn:function(ref){
var row = ref.row;
return [(row.isLinked ==1)?_c('div',[_vm._v(" 是 ")]):_c('div',[_vm._v(" 否 ")])]}},{key:"isShelve",fn:function(ref){
var row = ref.row;
return [(row.isShelve ==1)?_c('div',[_vm._v(" 是 ")]):_c('div',[_vm._v(" 否 ")])]}},{key:"profit",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.profit=='是'?'是': '否')),_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.profitFlag && row.profitFlag==1),expression:"row.profitFlag && row.profitFlag==1"}],staticClass:"profitRed"},[_vm._v("变")])])]}},{key:"channelType",fn:function(ref){
var row = ref.row;
return [(row.collectionType)?_c('div',[(row.collectionType === '单开')?_c('span',[_vm._v("单开")]):_c('a',{staticClass:"text--link",on:{"click":function($event){return _vm.handleSubset(row)}}},[_vm._v("合集")])]):_c('div',[_vm._v(" - ")])]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [_c('a',{on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v(" 设置 ")]),_c('a',{on:{"click":function($event){return _vm.handleTimeline(row)}}},[_vm._v(" 时间线 ")])]}}])}),(_vm.showDialog)?_c('AddDrawer',{attrs:{"is-edit-sidebar-active":_vm.showDialog,"curr-item":_vm.currItem,"active":_vm.active,"show-edit":_vm.showEdit,"category-list":_vm.settingCategory},on:{"update:isEditSidebarActive":function($event){_vm.showDialog=$event},"update:is-edit-sidebar-active":function($event){_vm.showDialog=$event},"success":_vm.handleRefresh},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e(),(_vm.batchShowDialog)?_c('BatchSettings',{attrs:{"curr-item":_vm.currItem,"search-parms":_vm.searchParms,"batchset-tit":_vm.batchsetTit,"active":_vm.active,"checked-obj":_vm.checkedObj,"category-list":_vm.settingCategory},on:{"success":_vm.handleRefresh},model:{value:(_vm.batchShowDialog),callback:function ($$v) {_vm.batchShowDialog=$$v},expression:"batchShowDialog"}}):_vm._e(),(_vm.showXlsx)?_c('importXlsx',{on:{"success":_vm.handleRefresh},model:{value:(_vm.showXlsx),callback:function ($$v) {_vm.showXlsx=$$v},expression:"showXlsx"}}):_vm._e(),(_vm.showSetting)?_c('Setting',{attrs:{"curr-item":_vm.currItem},on:{"success":_vm.handleRefresh},model:{value:(_vm.showSetting),callback:function ($$v) {_vm.showSetting=$$v},expression:"showSetting"}}):_vm._e(),(_vm.showSubset)?_c('Subset',{attrs:{"curr-item":_vm.currItem,"active":_vm.active},model:{value:(_vm.showSubset),callback:function ($$v) {_vm.showSubset=$$v},expression:"showSubset"}}):_vm._e(),(_vm.showTimeline)?_c('Timeline',{attrs:{"curr-item":_vm.currItem,"type":"homePage"},model:{value:(_vm.showTimeline),callback:function ($$v) {_vm.showTimeline=$$v},expression:"showTimeline"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }