<!-- eslint-disable no-param-reassign -->
<!-- eslint-disable eqeqeq -->
<script>
import {
  defineComponent, ref, computed,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { channelExport } from '@/api/accountHomePageAdd'
import { compareOptions } from '@core/utils/options'
import tableCols from './tableCols'
import useSearch from './useSearch'
import Setting from './components/Setting/Setting.vue'
import Subset from './components/Subset/Subset.vue'
import Timeline from './components/Timeline/Timeline.vue'
import Add from './components/Add/Add.vue'
import AddDrawer from './components/AddDrawer/AddDrawer.vue'
import BatchSettings from './components/BatchSettings/BatchSettings.vue'
import importXlsx from './components/importXlsx/importXlsx.vue'

export default defineComponent({
  components: {
    Setting,
    Subset,
    Timeline,
    Add,
    AddDrawer,
    BatchSettings,
    importXlsx,
  },
  setup() {
    const showTimeline = ref(false)
    const showSetting = ref(false)
    const showSubset = ref(false)
    const currItem = ref({})
    const showEdit = ref(false)
    const checkedObj = ref([])
    const showXlsx = ref(false)
    const searchParms = ref({})
    const categoryList = ref([])

    const { message } = useMessage()

    const handleTimeline = row => {
      showTimeline.value = true
      currItem.value = row
    }

    const handleSubset = row => {
      showSubset.value = true
      currItem.value = row
    }

    const handleSetting = row => {
      showSetting.value = true
      currItem.value = row
    }

    const {
      tabsConfig, queryDataOri, fetchList, searchConfig, queryData, active, search, pageList, pageLength, ...searchOthers
    } = useSearch()

    // 分tab处理table和search配置
    const tableColsFilter = computed(() => tableCols.filter(x => !x.active || x.active === active.value))
    const searchConfigFilter = computed(() => searchConfig.value.filter(x => !x.active || x.active === active.value))
    const batchsetTit = computed(() => (checkedObj.value.length > 0 ? '批量设置' : '全部设置'))

    const showDialog = ref(false)
    const handleAdd = () => {
      showEdit.value = false
      showDialog.value = true
    }
    const handleEdit = row => {
      showEdit.value = true
      showDialog.value = true
      currItem.value = row
    }

    const batchShowDialog = ref(false)

    const handleBatchsetting = () => {
      batchShowDialog.value = true
    }

    const handleXlsx = () => {
      showXlsx.value = true
    }

    const handleRefresh = () => {
      checkedObj.value = []
      fetchList()
    }
    const selectChange = obj => {
      checkedObj.value = obj
    }
    const clearCheckboxRow = () => {
      checkedObj.value = []
    }
    const handerSearch = params => {
      checkedObj.value = []
      search(params)
      searchParms.value = params
    }

    const handleDownloadTemplate = (url, name) => {
      const link = document.createElement('a')
      link.download = name // 下载文件名称
      link.href = url
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    const handleExport = async () => {
      if (pageLength.value <= 0) {
        message.error('您没有可导出的内容')

        return
      }

      if (pageLength.value >= 10000) {
        message.error('请缩小筛选范围，系统只允许导出10000条数据')

        return
      }
      let newParms = {
      }
      if (Object.keys(searchParms.value).length && Object.keys(searchParms.value).length > 0) {
        newParms = { ...searchParms.value }
      }
      newParms.type = active.value === 'youtube' ? 0 : 1

      const {
        subscribe, compareSubscribe, videoChangeCount, compareVideoChangeCount, videoCount, compareVideoCount, ...others
      } = queryData

      let pageStatus = []
      if (active.value === 'youtube') {
        pageStatus = others.pageStatus
      } else {
        pageStatus = others.pageStatus === null ? [] : [others.pageStatus]
      }
      newParms = {
        ...newParms,
        ...others,
        subscribe: {
          digit: subscribe,
          symbol: compareOptions.find(x => x.value === compareSubscribe).label,
        },
        videoCount: {
          digit: videoCount,
          symbol: compareOptions.find(x => x.value === compareVideoCount).label,
        },
        differenceValue: {
          digit: videoChangeCount,
          symbol: compareOptions.find(x => x.value === compareVideoChangeCount).label,
        },
        pageStatus,
      }

      const blob = await channelExport(newParms)
      const url = URL.createObjectURL(blob.data)
      handleDownloadTemplate(url, '导出文件.xlsx')
    }

    return {
      active,
      tabsConfig,
      tableCols: tableColsFilter,
      searchConfig: searchConfigFilter,
      ...searchOthers,
      showSetting,
      showSubset,
      showTimeline,
      currItem,
      searchParms,
      batchsetTit,

      handleTimeline,
      handleSubset,
      handleSetting,
      pageList,
      pageLength,

      showEdit,
      showDialog,
      handleRefresh,
      handleAdd,
      handleEdit,
      handleBatchsetting,
      handleExport,

      selectChange,
      clearCheckboxRow,
      checkedObj,
      batchShowDialog,
      handerSearch,
      search,

      showXlsx,
      handleXlsx,
      handleDownloadTemplate,
      categoryList,

    }
  },
})
</script>

<template>
  <AppView>
    <template #header>
      <div style="width: 100%">
        <XwTabs
          :tabs="tabsConfig"
          :active="active"
          @change="handleTabChange"
        ></XwTabs>
        <div style="padding: 12px 0">
          <!-- 搜索区域 测试git提交 -->
          <!-- @search="search" -->
          <XwSearch
            ref="searchRef"
            :form-config="searchConfig"
            @search="handerSearch"
          />
        </div>
      </div>
    </template>

    <template #main-header>
      <!--新增-->
      <div class="pb-2">
        <!--按钮区域-->
        <v-btn
          outlined
          class="mr-3"
          @click="handleAdd"
        >
          新增
        </v-btn>

        <v-btn
          outlined
          class="btn-item mr-3"
          @click="handleBatchsetting"
        >
          {{ batchsetTit }}
        </v-btn>

        <v-btn
          v-show="active=='youtube'"
          outlined
          class="btn-item mr-3"
          @click="handleXlsx"
        >
          <v-icon
            left
            blue
          >
            mdi-cloud-upload
          </v-icon>
          导入获利状态
        </v-btn>

        <v-btn
          outlined
          class="btn-item mr-3"
          @click="handleExport"
        >
          导出
        </v-btn>
      </div>
    </template>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <XwTable
      ref="tableRef"
      :columns="tableCols"
      :rows="pageList"
      :row-config="{height: 76}"
      height="auto"
      @selectChange="selectChange"
      @clearCheckboxRow="clearCheckboxRow"
    >
      <template #ways="{row}">
        <div
          v-if="row.currentChannels && row.currentChannels.length"
          class="current-channel"
        >
          <div>
            <div
              v-for="(item, idx) in row.currentChannels"
              v-show="idx < 3"
              :key="idx"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-if="row.currentChannels.length > 3"
            class="more"
          >
            <v-tooltip right>
              <template
                #activator="{ on, attrs }"
              >
                <span
                  v-bind="attrs"
                  class="text--link pl10"
                  v-on="on"
                >更多</span>
              </template>
              <div>
                <div
                  v-for="(item, idx) in row.currentChannels"
                  :key="idx"
                >
                  {{ item }}
                </div>
              </div>
            </v-tooltip>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #copyrightHolders="{row}">
        <div
          v-if="row.copyrightHolders && row.copyrightHolders.length"
          class="current-channel"
        >
          <div>
            <div
              v-for="(item, idx) in row.copyrightHolders"
              v-show="idx < 3"
              :key="idx"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-if="row.copyrightHolders.length > 3"
            class="more"
          >
            <v-tooltip right>
              <template
                #activator="{ on, attrs }"
              >
                <span
                  v-bind="attrs"
                  class="text--link pl10"
                  v-on="on"
                >更多</span>
              </template>
              <div>
                <div
                  v-for="(item, idx) in row.copyrightHolders"
                  :key="idx"
                >
                  {{ item }}
                </div>
              </div>
            </v-tooltip>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #isLinked="{row}">
        <div v-if="row.isLinked ==1">
          是
        </div>
        <div v-else>
          否
        </div>
      </template>
      <template #isShelve="{row}">
        <div v-if="row.isShelve ==1">
          是
        </div>
        <div v-else>
          否
        </div>
      </template>

      <template #profit="{row}">
        <div>
          {{ row.profit=='是'?'是': '否' }}<span
            v-show="row.profitFlag && row.profitFlag==1"
            class="profitRed"
          >变</span>
        </div>
      </template>

      <template #channelType="{row}">
        <div v-if="row.collectionType">
          <span v-if="row.collectionType === '单开'">单开</span>
          <a
            v-else
            class="text--link"
            @click="handleSubset(row)"
          >合集</a>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #opts="{row}">
        <!-- <a
            @click="handleSetting(row)"
          >
            设置
          </a> -->
        <a
          @click="handleEdit(row)"
        >
          设置
        </a>
        <a
          @click="handleTimeline(row)"
        >
          时间线
        </a>
      </template>
    </XwTable>

    <template #footer>
      <new-pagination
        :page-index="page"
        :page-size="pageSize"
        :total="pageLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <!-- <Add
      v-if="showDialog"
      v-model="showDialog"
      :curr-item="currItem"
      :active="active"
      :show-edit="showEdit"
      :category-list="categoryOptions"
      @success="handleRefresh"
    /> -->

    <AddDrawer
      v-if="showDialog"
      v-model="showDialog"
      :is-edit-sidebar-active.sync="showDialog"
      :curr-item="currItem"
      :active="active"
      :show-edit="showEdit"
      :category-list="settingCategory"
      @success="handleRefresh"
    />

    <BatchSettings
      v-if="batchShowDialog"
      v-model="batchShowDialog"
      :curr-item="currItem"
      :search-parms="searchParms"
      :batchset-tit="batchsetTit"
      :active="active"
      :checked-obj="checkedObj"
      :category-list="settingCategory"
      @success="handleRefresh"
    />

    <importXlsx
      v-if="showXlsx"
      v-model="showXlsx"
      @success="handleRefresh"
    ></importXlsx>
    <!--设置-->
    <Setting
      v-if="showSetting"
      v-model="showSetting"
      :curr-item="currItem"
      @success="handleRefresh"
    ></Setting>

    <!--合集-->
    <Subset
      v-if="showSubset"
      v-model="showSubset"
      :curr-item="currItem"
      :active="active"
    ></subset>

    <!--时间轴-->
    <Timeline
      v-if="showTimeline"
      v-model="showTimeline"
      :curr-item="currItem"
      type="homePage"
    ></Timeline>
  </AppView>
</template>
<style lang="scss" scoped>
.current-channel{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
</style>
<style lang="scss">
.profitRed{
  display: inline-block;
  width: 16px;
  height: 16px;
  background: rgba(242,69,69,0.1);
  border-radius: 2px;
  font-size: 12px;
  color: #F24545;
  text-align: center;
  line-height: 16px;
  margin-left: 3px;
}
.theme--light.v-label {
    color:rgba(0,0,0,0.25);
}
</style>
