const tableCols = [
  {
    text: '视频',
    sortable: false,
    value: 'video',
    slots: 'video',
    width: 350,
  },
  {
    text: '发布频道',
    sortable: false,
    value: 'channelName',
    slots: 'channelName',
  },
  {
    text: '垂类',
    value: 'categoryStr',
  },
  {
    text: '源频道',
    sortable: false,
    value: 'sourceChannelName',
    slots: 'sourceChannelName',
  },
  {
    text: '观看次数',
    sortable: false,
    value: 'viewCount',
    align: 'right',
    formatter: ({ cellValue }) => (cellValue || cellValue === 0 ? cellValue.toLocaleString() : '-'),
  },
  {
    text: '评论数',
    sortable: false,
    value: 'commonCount',
    align: 'right',
    formatter: ({ cellValue }) => (cellValue || cellValue === 0 ? cellValue.toLocaleString() : '-'),
  },
  {
    text: '字幕',
    sortable: false,
    value: 'caption',
    formatter: ({ cellValue }) => (cellValue ? '有' : '无'),
  },
  {
    text: '时间',
    sortable: false,
    value: 'time',
    slots: 'time',
    width: 250,
  },

  // {
  //   text: '组别',
  //   sortable: false,
  //   value: 'group',
  // },
  // {
  //   text: '运营人员',
  //   sortable: false,
  //   value: 'operator',
  // },
]

export default tableCols
