<script>
import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@core/utils/validation'
import VCascader from '@core/components/search-form/VCascader.vue'
import { setCategory } from '../api'

export default defineComponent({
  components: {
    VCascader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const category = ref(null)
    const categoryObj = ref({})
    const formRef = ref()

    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const handleCategoryChange = (value, nodes = []) => {
      const [item] = nodes
      categoryObj.value = item
    }

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (!valid) return

      if (!category.value || !categoryObj.value) {
        message.error('请先选择垂类')

        return
      }

      try {
        const {
          data: { status, message: messageText },
        } = await setCategory({
          id: props.currItem.id,
          categoryId: category.value,
          parentCategoryId: categoryObj.value.parentId,
        })
        if (status === 200) {
          message.success('设置成功')
          handleClose()
          emit('success')
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    return {
      showDialog,
      category,
      formRef,
      required,
      handleClose,
      handleSubmit,
      handleCategoryChange,
    }
  },
})
</script>

<template>
  <v-dialog v-model="showDialog" persistent width="400">
    <v-card class="form-box">
      <h3 class="form-title">
        设置<span v-if="currItem.channelName">({{ currItem.channelName }})</span>
        <v-btn icon small title="关闭" style="position: absolute; top: 16px; right: 20px" @click="handleClose">
          <v-icon size="22"> mdi-close </v-icon>
        </v-btn>
      </h3>
      <v-form ref="formRef" class="current-info">
        <div class="current-item">
          <label>垂类</label>
          <div>
            <VCascader
              v-model="category"
              :items="categoryOptions"
              item-text="name"
              item-value="id"
              style="width: 300px"
              @change="handleCategoryChange"
            />
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="handleClose"> 取消 </v-btn>
        <v-btn color="primary" depressed @click="handleSubmit"> 确认 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box {
  padding: 20px 0 0 0;
  .form-title {
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #f2f3f5;
    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: normal;
    }
  }
  label {
    display: block;
    width: 64px;
    text-align: left;
    height: 34px;
    padding-top: 7px;
    color: rgba(0, 0, 0, 0.85);
  }
  ::v-deep .v-card__actions .v-btn {
    min-width: 65px;
  }
  .current-info {
    padding: 20px 20px 0 20px;
    font-size: 14px;
    .current-item {
      display: flex;
      align-items: left;
      margin-bottom: 10px;
      ::v-deep .v-input--selection-controls {
        margin-top: -2px;
      }
      ::v-deep .v-input--radio-group__input {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .v-radio {
          margin-right: 20px;
        }
      }
      ::v-deep .v-input--selection-controls {
        margin-top: -2px;
      }
      ::v-deep .v-label {
        font-size: 14px;
      }
      ::v-deep .v-input--radio-group__input {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .v-radio {
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
      ::v-deep .v-input--selection-controls__input {
        width: 16px;
        height: 16px;
      }
      .radio-item {
        display: flex;
        align-items: center;
        label {
          width: 50px;
          text-align: left;
        }
      }
    }
  }
}
</style>
