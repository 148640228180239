<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

import { Progress } from 'element-ui'

export default defineComponent({
  components: {
    Progress,
  },
  props: {
    isClose: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const percentage = ref(0)
    let time = null

    const createRandomPercentage = () => {
      const spe = Math.floor(Math.random() + 1) * 10
      const next = percentage.value + spe

      return next > 100 ? 100 : next
    }

    const handleCloseTimeout = () => {
      clearTimeout(time)
      time = null
    }

    const handleOpenTimeout = () => {
      handleCloseTimeout()

      time = setInterval(() => {
        if (percentage.value >= 100) {
          if (props.isClose) {
            handleCloseTimeout()

            setTimeout(() => {
              emit('close')
              percentage.value = 0
            }, 1000)
          }

          return
        }

        percentage.value = createRandomPercentage()
      }, 200)
    }

    watch(
      () => props.visible,
      () => {
        if (props.visible) {
          handleOpenTimeout()
        }

        if (!props.visible) {
          handleCloseTimeout()
        }
      },
      {
        immediate: true,
        deep: true,
      },
    )

    return {
      percentage,
    }
  },
})
</script>

<template>
  <vl-dialog :visible="visible" title="正在导入" width="400px" top="300px">
    <Progress :stroke-width="10" :percentage="percentage"></Progress>
    <span class="loadingTxt">数据校验中...</span>
  </vl-dialog>
</template>

<style lang="scss" scoped>
::v-deep {
  .vl-dialog__headerbtn {
    display: none;
  }

  .el-progress__text {
    font-weight: 500;
    font-size: 12px !important;
    margin-left: 15px;
    color: rgba(0,0,0,0.5) !important;
  }
}

.loadingTxt {
  display: inline-block;
  margin-top: 7px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
</style>
