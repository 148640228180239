// eslint-disable-next-line import/no-extraneous-dependencies
import ResizeObserver from 'resize-observer-polyfill'
import { debounce } from '@prettyy/utils'

function resizeHandler(entries) {
  for (const entry of entries) {
    const listener = entry.target.__resizeListeners__ || []
    if (listener.length) {
      listener.forEach(fn => fn())
    }
  }
}

// 监听Dom 尺寸变化
export function addResizeListener(element, fu) {
  if (!element.__resizeListeners__) {
    element.__resizeListeners__ = []
    element.__ro__ = new ResizeObserver(debounce(16, resizeHandler))
    element.__ro__.observe(element)
  }
  element.__resizeListeners__.push(fu)
}

// 移除Dom尺寸监听
export function removeResizeListener(element, fu) {
  if (!element || !element.__resizeListeners__) return false
  element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fu), 1)
  if (!element.__resizeListeners__.length) {
    element.__ro__.disconnect()
  }
}
