<script>
import { ref, computed, onUpdated, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { addResizeListener, removeResizeListener } from '@/utils/resize-event'

function noop() {}
export default {
  name: 'TabsNav',
  props: {
    tabItemArr: {
      type: Array,
      default: () => ([]),
    },
    activeName: {
      type: String,
      default: '',
    },
    onTabClick: {
      type: Function,
      default: noop,
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const nav = ref()
    const navScroll = ref()
    // eslint-disable-next-line no-shadow
    const scrollable = ref(false)
    const navOffset = ref(0)
    function changeActiveName(tabItem) {
      if (tabItem.name === props.activeName) {
        return
      }
      if (tabItem.disabled) {
        return
      }
      this.onTabClick(tabItem)
    }

    const navStyle = computed(() => ({
      transform: `translateX(-${navOffset.value}px)`,
    }))

    function update() {
      if (!nav.value) return false
      const navSize = nav.value.offsetWidth
      const containerSize = navScroll.value.offsetWidth
      const currentOffset = navOffset.value

      if (containerSize < navSize) {
        // eslint-disable-next-line no-shadow
        const currentOffset = navOffset.value
        scrollable.value = scrollable.value || {}
        scrollable.value.prev = currentOffset
        scrollable.value.next = currentOffset + containerSize < navSize
        if (navSize - currentOffset < containerSize) {
          navOffset.value = navSize - containerSize
        }
      } else {
        scrollable.value = false
        if (currentOffset > 0) {
          navOffset.value = 0
        }
      }
    }

    // 上一个
    function scrollPrev() {
      const containerSize = navScroll.value.offsetWidth
      const currentOffset = navOffset.value

      if (!currentOffset) return false
      const newOffset = currentOffset > containerSize ? currentOffset - containerSize : 0
      navOffset.value = newOffset
    }

    // 下一个
    function scrollNext() {
      const navSize = nav.value.offsetWidth
      const containerSize = navScroll.value.offsetWidth
      const currentOffset = navOffset.value
      if (navSize - currentOffset <= containerSize) return false
      const newOffset = navSize - currentOffset > containerSize * 2 ? currentOffset + containerSize : (navSize - containerSize)
      navOffset.value = newOffset
    }

    function scrollToActiveTab() {
      if (!scrollable.value) return false
      const navRef = nav.value
      const activeTab = navScroll.value.querySelector('.highLight')
      if (!activeTab) return false
      const navScrollRef = navScroll.value
      const activeTabBounding = activeTab.getBoundingClientRect()
      const navScrollBounding = navScrollRef.getBoundingClientRect()
      const maxOffset = navRef.offsetWidth - navScrollBounding.width
      const currentOffset = navOffset.value
      let newOffset = currentOffset

      if (activeTabBounding.left < navScrollBounding.left) {
        newOffset = currentOffset - (navScrollBounding.left - activeTabBounding.left)
      }
      if (activeTabBounding.right > navScrollBounding.right) {
        newOffset = currentOffset + activeTabBounding.right - navScrollBounding.right
      }

      newOffset = Math.max(newOffset, 0)
      navOffset.value = Math.min(newOffset, maxOffset)
    }

    onUpdated(() => {
      update()
    })

    onMounted(() => {
      addResizeListener(navScroll.value, update)
      setTimeout(() => {
        scrollToActiveTab()
      })
    })

    onBeforeUnmount(() => {
      if (navScroll.value && update) removeResizeListener(navScroll.value, update)
    })

    return {
      changeActiveName,
      navScroll,
      nav,
      navStyle,
      scrollable,
      scrollPrev,
      scrollNext,
    }
  },
}
</script>

<template>
  <div class="tab-nav__wrapper" :class="[scrollable ? 'is-scrollable' : '']">
    <div v-if="scrollable" class="tab-nav-scroll-btn left" @click.stop="scrollPrev">
      <v-icon>mdi-chevron-left</v-icon>
    </div>
    <div v-if="scrollable" class="tab-nav-scroll-btn right" @click.stop="scrollNext">
      <v-icon>mdi-chevron-right</v-icon>
    </div>
    <div ref="navScroll" class="tab-nav_scroll">
      <div ref="nav" class="tab-nav-item-box" :style="navStyle" :class="[type === 'succinct' ? 'succinct' : '']">
        <div
          v-for="(tabItem, index) in tabItemArr"
          :key="index"
          :class="[
            'tab-nav-item',
            tabItem.name === activeName ? 'highLight' : '',
            tabItem.disabled ? 'isForbiddenItem' : '',
            type === 'succinct' ? 'succinct-item' : ''
          ]"
          @click="changeActiveName(tabItem)"
        >
          {{ tabItem.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.tab-nav-item-box {
  transition: transform .3s;
  white-space: nowrap;
  position: relative;
  float: left;
  z-index: auto;

  .tab-nav-item {
    display: inline-block;
    flex-shrink: 0;
    height: 45px;
    min-width: 104px;
    text-align: center;
    line-height: 45px;
    font-size: 15px;
    font-weight: 500;
    padding: 0 12px;
    cursor: pointer;
    background-color: #F6F7F8;
    transition: all 0.3s;
    border: 1px solid #E8E9EC;
    border-bottom: 0;

    // 非禁用时
    &:not(.isForbiddenItem):hover {
      color: #9155fd;
    }

    &:first-child {
      border-radius: 6px 0 0 0;
    }

    &:last-child {
      border-radius: 0 6px 0 0;
    }
  }
  .tab-nav-item + .tab-nav-item {
    border-left: 0;
  }

  // 高亮项样式
  .highLight {
    color: #9155fd;
    background-color: #FFFFFF;
  }

  // 禁用样式
  .isForbiddenItem {
    cursor: not-allowed;
    color: #aaa;
  }
}
.tab-nav__wrapper {
  position: relative;
}
.tab-nav_scroll {
  width: 100%;
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;
  border-bottom: thin solid rgba(94, 86, 105, 0.14);
}

.succinct {
  &-item {
    position: relative;
    transition: all .2s linear;
    background: #ffffff !important;
    border: none !important;

    &::after {
      transition: all 0.2s linear;
      transform: translateX(-50%) translateY(50%) scaleX(0);
      content: '';
      width: 100%;
      position: absolute;
      left: 50%;
      bottom: 0;
      border-bottom: 2px solid var(--v-primary-base);
      border-radius: 4px;
    }

    &.highLight {
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(50%) scaleX(1);
        bottom: 0;
        border-bottom: 4px solid var(--v-primary-base);
      }
    }
  }
}

.is-scrollable {
  padding: 0 20px;
}

.tab-nav-scroll-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  background-color: #fff;
  z-index: 3;
}
.left {
  left: 0;
}

.right {
  right: 0;
}
</style>
