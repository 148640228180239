<script>
import {
  defineComponent, computed, reactive, ref, onMounted, watch
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import {
  fieldOptions, operationGroupOptionsByYtnews, operationGroupOptionsByFbnews, levelOptionsnews, channelStates,
  contentClass, homepageProperies, homepageStatus, operationModeYTOptions, cidPolicyOpts,
} from '@core/utils/options'
import { channelCreate, channelConfig, getNewSubset } from '@/api/accountHomePageAdd'
import DatePickers from '@core/components/search-form/DateRangePickers.vue'
import dayjs from 'dayjs'
import VCascaderVue from '@core/components/search-form/VCascader.vue'
import { getCategory } from '@/views/audit/clueAudit/api'

export default defineComponent({
  components: {
    DatePickers,
    VCascaderVue,
  },
  model: {
    prop: 'isEditSidebarActive',
    event: 'update:is-edit-sidebar-active',
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: 'channel',
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    showEdit: {
      type: [Boolean, String],
      default: false,
    },
    categoryList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formData = reactive({
      channelId: null,
      id: null,
      channelName: null,
      categoryId: null,
      parentCategoryId: null,
      operationTeam: null,
      collectionType: 1,
      profit: null,
      incomeScale: null,
      registerDate: '',
      profitDate: '',
      contentType: null, // facebook
      pageAttribute: null,
      pageStatus: 1, // facebook 编辑 默认正常运行
      isShelve: null, // facebook 编辑 搁置
      cidFlag: null, // 新增
      cidPolicy: 'S661566637357110', // 匹配政策
    })
    const formRef = ref()
    const showDialog = computed(() => props.value)
    const showEdits = ref(false)
    const titleTop = ref('新增')
    const { message } = useMessage()
    const categoryObj = ref({})
    const isYoutube = computed(() => props.active === 'youtube')
    const channelId = props.active === 'youtube' ? '频道ID' : '主页ID'
    const channelName = props.active === 'youtube' ? '频道名' : '主页名'
    const subsetList = ref([]) // 新增子集
    const cidDisable = ref(false)

    const handleClose = () => {
      emit('update:is-edit-sidebar-active', false)
    }

    const handleCategoryChange = (value, nodes = []) => {
      const [item] = nodes
      categoryObj.value = item
    }

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      let youTuIs = true
      const re = /^UC[0-9A-Za-z_-]{21}[AQgw]/
      if (!re.test(formData.id) && isYoutube.value && !showEdits.value) {
        message.error('频道ID格式不正确')
        youTuIs = false
      }

      if (!valid || !youTuIs) return
      try {
        let postChannel = channelCreate // isYoutube 区分 true youTube facebook , 新增type: 0 youTube 1 facebook
        if (showEdits.value) {
          postChannel = channelConfig
        }
        if (!showEdits.value && !isYoutube.value) {
          formData.pageStatus = 1
        }
        let overseaChannelSubsetList = []
        overseaChannelSubsetList = subsetList.value.filter(item => item.subsetName.length > 0)

        const parameter = {
          id: formData.id,
          channelName: formData.channelName,
          categoryId: formData.categoryId,
          parentCategoryId: categoryObj.value.parentId || props.currItem.parentCategoryId,
          operationTeam: formData.operationTeam,
          collectionType: formData.collectionType,
          profit: formData.profit,
          incomeScale: formData.incomeScale,
          registerDate: formData.registerDate,
          profitDate: formData.profitDate,
          contentType: formData.contentType, // facebook
          pageAttribute: formData.pageAttribute,
          pageStatus: [formData.pageStatus], // facebook 编辑
          isShelve: formData.isShelve, // facebook 编辑 搁置
          type: isYoutube.value ? 0 : 1, // 0 youTube 1 facebook
          oprationMode: formData.oprationMode,
          overseaChannelSubsetList,
        }
        if (isYoutube.value) {
          parameter.cidFlag = formData.cidFlag
          parameter.cidPolicy = formData.cidFlag === 1 ? formData.cidPolicy : 0
        }

        const { data: { status, message: messageText } } = await postChannel(parameter)
        if (status === 200) {
          message.success('提交成功')
          emit('success')
          handleClose()
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    const addSubset = () => {
      const item = { subsetName: '' }
      subsetList.value.push(item)
    }
    const deleteSubset = index => {
      subsetList.value.splice(index, 1)
    }

    const getSubsetList = async () => {
      const { data } = await getNewSubset({ channelId: props.currItem.id })
      subsetList.value = data.data.subset || []
      cidDisable.value = data.data.cidShowFlag === 1
    }

    onMounted(() => {
      if (props.showEdit) {
        Object.assign(formData, props.currItem)
        formData.operationTeam = props.currItem.operationTeamValue
        formData.collectionType = props.currItem.collectionTypeValue
        formData.profit = props.currItem.profit === '是' ? 1 : 0
        formData.incomeScale = props.currItem.incomeScale ? props.currItem.incomeScale.toString() : props.currItem.incomeScale
        formData.registerDate = props.currItem.registerDate ? dayjs(props.currItem.registerDate).format('YYYY-MM-DD') : ''
        formData.profitDate = props.currItem.profitDate ? dayjs(props.currItem.profitDate).format('YYYY-MM-DD') : ''
        formData.contentType = props.currItem.contentTypeValue
        formData.pageAttribute = props.currItem.pageAttributeValue ? props.currItem.pageAttributeValue.toString() : props.currItem.pageAttributeValue
        formData.cidPolicy = props.currItem.cidPolicy === '0' ? null : props.currItem.cidPolicy
        titleTop.value = '设置'
        showEdits.value = true
        getSubsetList()
      }
    })

    return {
      formData,
      showDialog,
      formRef,
      required,
      isYoutube,
      handleClose,
      handleSubmit,
      handleCategoryChange,
      fieldOptions, // 下拉
      operationGroupOptionsByYtnews,
      operationGroupOptionsByFbnews,
      levelOptionsnews,
      channelStates,
      contentClass,
      homepageProperies,
      homepageStatus,
      operationModeYTOptions,
      cidPolicyOpts,

      titleTop,
      channelId,
      channelName,
      showEdits,
      icons: {
        mdiClose,
      },
      subsetList,
      cidDisable,
      addSubset,
      deleteSubset,
      getSubsetList,
    }
  },
})
</script>

<template>
  <v-navigation-drawer
    :value="isEditSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    app
    @input="(val) => $emit('update:is-edit-sidebar-active', val)"
    style="z-index: 99;"
    permanent
  >
    <v-card class="form-drawer-box">
      <h3 class="form-title">
        <span>{{ titleTop }}</span>
        <v-btn
          icon
          small
          @click="$emit('update:is-edit-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </h3>
      <v-form
        ref="formRef"
        class="form-outer"
      >
        <div class="form-inner">
          <div
            class="form-item" v-if="showEdits"
          >
            <label>{{ channelId }}</label>
            <div>
              <v-tooltip right>
                <template
                  #activator="{ on, attrs }"
                >
                  <span
                    v-bind="attrs"
                    class="text--link edit-des"
                    v-on="on"
                  >{{ formData.id }}</span>
                </template>
                <div>
                  <div>
                    {{ formData.id }}
                  </div>
                </div>
              </v-tooltip>
            </div>
          </div>
          <div
            class="form-item" v-if="showEdits"
          >
            <label>{{ channelName }}</label>
            <div>
              <span
                class="edit-des"
              >{{ formData.channelName }}</span>
            </div>
          </div>

          <div class="form-tits">基本信息</div>
          <div
            class="form-item" v-if="!showEdits"
          >
            <label>{{ channelId }}</label>
            <div>
              <v-text-field
                v-model="formData.id"
                :rules="[required]"
                dense
                hide-details
                outlined
                :placeholder="channelId"
                style="width: 250px"
              ></v-text-field>
            </div>
          </div>
          <div
            class="form-item" v-if="!showEdits"
          >
            <label>{{ channelName }}</label>
            <div>
              <v-text-field
                v-model="formData.channelName"
                :rules="[required]"
                dense
                hide-details
                outlined
                :placeholder="channelName"
                style="width: 250px"
              ></v-text-field>
            </div>
          </div>
          <div
            class="form-item"
          >
            <label>垂类</label>
            <div>
              <VCascaderVue
                v-model.trim="formData.categoryId"
                :items="categoryList"
                no-data-text="暂无数据"
                hide-details
                placeholder="垂类"
                dense
                outlined
                clearable
                item-text="name"
                item-value="id"
                style="width: 250px"
                @change="handleCategoryChange"
              ></VCascaderVue>
            </div>
          </div>

          <div
            v-if="!isYoutube"
            class="form-item"
          >
            <label>内容分类</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.contentType"
                :items="contentClass"
                no-data-text="暂无数据"
                hide-details
                placeholder="内容分类"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            v-if="isYoutube"
            class="form-item"
          >
            <label>运营类型</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageAttribute"
                :items="operationModeYTOptions"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营类型"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            v-if="!isYoutube"
            class="form-item"
          >
            <label>主页属性</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageAttribute"
                :items="homepageProperies"
                no-data-text="暂无数据"
                hide-details
                placeholder="主页属性"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>运营团队</label>
            <div>
              <v-autocomplete
                v-if="isYoutube"
                v-model.trim="formData.operationTeam"
                :items="operationGroupOptionsByYtnews"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营团队"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
              <v-autocomplete
                v-if="!isYoutube"
                v-model.trim="formData.operationTeam"
                :items="operationGroupOptionsByFbnews"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营团队"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>单开/合集</label>
            <div class="singeRadio">
              <v-radio-group
                v-model="formData.collectionType"
                row
              >
                <v-radio
                  label="单开"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="合集"
                  :value="0"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>运营级别</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.incomeScale"
                :items="levelOptionsnews"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营级别"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            v-if="isYoutube"
            class="form-item"
          >
            <label>频道状态</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageStatus"
                :items="channelStates"
                no-data-text="暂无数据"
                hide-details
                placeholder="频道状态"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            v-if="!isYoutube && showEdits"
            class="form-item"
          >
            <label>主页状态</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageStatus"
                :items="homepageStatus"
                no-data-text="暂无数据"
                hide-details
                placeholder="主页状态"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>注册日期</label>
            <div class="w200">
              <DatePickers
                v-model="formData.registerDate"
                :multiple="false"
                :range="false"
                clearable
              ></DatePickers>
            </div>
          </div>
        </div>
        <div class="form-inner">
          <div
            class="form-item"
          >
            <label>获利</label>
            <div class="switch-box">
              <div class="d-flex align-center">
                <span id="switch-des" class="text--secondary text-sm first-secondary" >开</span>
                <v-switch
                  v-model="formData.profit"
                  hide-details
                  class="mt-0 mx-2"
                  :true-value="1"
                  :false-value="0"
                  inset
                ></v-switch>
                <span id="switch-des" class="text--secondary text-sm second-secondary">关</span>
              </div>
            </div>
          </div>

          <div
            v-if="!isYoutube && showEdits"
            class="form-item"
          >
            <label>搁置</label>
            <div class="switch-box">
              <div class="d-flex align-center">
                <span id="switch-des" class="text--secondary text-sm first-secondary" >开</span>
                <v-switch
                  v-model="formData.isShelve"
                  hide-details
                  class="mt-0 mx-2"
                  :true-value="1"
                  :false-value="0"
                  inset
                ></v-switch>
                <span id="switch-des" class="text--secondary text-sm second-secondary">关</span>
              </div>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>获利日期</label>
            <div class="w200">
              <DatePickers
                v-model.trim="formData.profitDate"
                clearable
              ></DatePickers>
            </div>
          </div>

          <div
            class="form-item"
            v-if="isYoutube"
          >
            <label>
              开启CID
              <v-tooltip bottom>
                <template
                  #activator="{ on, attrs }"
                >
                  <span
                    v-bind="attrs"
                    class="ml-1 iconfont icon-a-tishishuoming2x2"
                    style="font-size: 14px;"
                    v-on="on"
                  ></span>
                </template>
                <div>若开启CID，该频道上传至YT的视频将自动开启CID版权保护，开启CID后需要选择匹配政策；自运营频道不支持手动开启/关闭CID</div>
              </v-tooltip>
            </label>
            <div class="switch-box">
              <div class="d-flex align-center">
                <span
                  id="switch-des" class="text--secondary text-sm first-secondary">开</span>
                <v-switch
                  v-model="formData.cidFlag"
                  hide-details
                  class="mt-0 mx-2"
                  :true-value="1"
                  :false-value="0"
                  :disabled="cidDisable"
                  inset
                ></v-switch>
                <span id="switch-des" class="text--secondary text-sm second-secondary">关</span>
              </div>
            </div>
          </div>

          <div
            class="form-item" v-if="formData.cidFlag == 1 && isYoutube"
          >
            <label>匹配政策</label>
            <!-- disabled -->
            <div>
              <v-autocomplete
                v-model.trim="formData.cidPolicy"
                :items="cidPolicyOpts"
                :rules="[required]"
                no-data-text="暂无数据"
                hide-details
                placeholder="匹配政策"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                :disabled="cidDisable"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div class="form-tits">频道子集</div>
          <div
            class="form-item"
            v-for="(item,i) in subsetList" :key="'sub'+i">
            <label>子集名称</label>
            <div>
              <v-text-field
                v-model="item.subsetName"
                dense
                hide-details
                outlined
                placeholder="请输入"
                single-line
                style="width: 250px"
                maxlength="500"
              ></v-text-field>
              <span
                class="iconfont ml-2 icon-a-shanchu2x delete"
                @click="deleteSubset(i)"
              ></span>
            </div>
          </div>
          <div class="form-add-subset" @click="addSubset">
            <span
              class="iconfont ml-2 icon-a-xinzeng2x3"
            ></span>
            新增子集
          </div>
          <!-- 新增部分结束 -->
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          outlined
          class="mr-3"
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>
<style lang="scss" scoped>
@import './addDrawer.scss';
</style>
<style lang="scss">
@import './addDrawerPublic.scss';
</style>
