<script>
import { useMessage } from '@/hooks/useMessage'
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import SuperTable from '@/components/SuperTable/index.vue'
import IconPlatform from '@/components/IconPlatform.vue'
import ToolsBox from '@/views/ways/publishWays/components/ToolsBox.vue'
import AddData from '@/views/ways/publishWays/components/AddData.vue'
import AppViewTow from '@/components/AppViewTow.vue'
import { worksType } from '@core/utils/options'
import useSearch from './useSearch'
import Delete from './components/Delete.vue'
import ExportTemplate from './components/ExportTemplate.vue'

export default defineComponent({
  components: {
    AppViewTow,
    AddData,
    ToolsBox,
    IconPlatform,
    SuperTable,
    TabsPro,
    TabPanePro,
    ExportTemplate,
    Delete,
  },
  setup() {
    const { message } = useMessage()
    const tabsConfig = reactive([
      { name: '签约频道', key: 'channel' },
      { name: '视听作品', key: 'others' },
    ])
    const tableRef = ref()
    const selected = ref([])
    const ExportTemplateDialog = ref(false)
    const showDialog = ref(false)
    const visibleDelete = ref(false)
    const visibleAdd = ref(false)
    const currItem = ref({})
    const { queryDataOri, fetchList, searchConfig, active, queryData, ...searchOthers } = useSearch()

    // 分tab处理table和search配置
    const searchConfigFilter = computed(() => searchConfig.value.filter(x => !x.active || x.active === active.value))
    const tableColumns = [
      { label: '', props: 'checkbox', type: 'checkbox', width: 50 },
      {
        label: '签约内容频道',
        props: 'signPlatform',
        minWidth: 120,
        hidden: computed(() => !['channel'].includes(active.value)),
      },
      {
        label: '签约内容作品名',
        props: 'signChannelName',
        hidden: computed(() => !['others'].includes(active.value)),
        minWidth: 120,
      },
      { label: 'CP名称', props: 'copyrightHolder', minWidth: 100 },
      { label: '签约内容链接', props: 'signUrl', minWidth: 210 },
      { label: '注册频道/主页', props: 'registerChannelName', minWidth: 150 },
      { label: '套餐名称', props: 'servicePageName', minWidth: 150 },
      { label: '作品类型', props: 'compositionType', width: 110, hidden: computed(() => !['others'].includes(active.value)) },
      { label: '视频标签/播放列表', props: 'playlist', minWidth: 150 },
      { label: '创建人', props: 'createdUserName', width: 100 },
      { label: '创建时间', props: 'createdAt', width: '170px' },
      { label: '操作', props: 'opts', width: 100, fixed: 'right' },
    ]
    const handleRefresh = () => {
      fetchList()
      tableRef.value.clearCheckboxReserve()
    }

    const selectionChange = data => {
      selected.value = data
    }
    const rowPlatform = ref(0)
    const handleAdd = e => {
      rowPlatform.value = e
      visibleAdd.value = true
    }

    const handleExport = () => {
      ExportTemplateDialog.value = true
    }

    const deleteData = ref([])
    const deleteItem = ref({})
    const handleRemove = async item => {
      const { signChannelName, signPlatform, registerChannelName, registerPlatform } = item
      deleteItem.value = {
        ...item,
        name: registerChannelName,
        platform: registerPlatform,
        activeType: active.value === 'channel' ? 0 : 1,
        title: '删除',
      }
      deleteData.value = [{
        name: signChannelName || '', platform: signPlatform || '', id: item.id,
      }]
      visibleDelete.value = true
    }

    // 是否存在多个频道/主页ID
    const hasMultiple = data => {
      const channelIds = data.map(item => item.registerChannelId)
      const uniqueChannelIds = new Set(channelIds)

      return uniqueChannelIds.size > 1
    }

    // 批量删除
    const batchDelete = async () => {
      if (!selected.value.length) return message.warning('请选择需要删除的信息')
      if (hasMultiple(selected.value)) return message.warning('只支持删除同一个频道/主页的通道')
      deleteData.value = selected.value.map(item => ({
        name: item.signChannelName || '', platform: item.signPlatform || '', id: item.id,
      }))
      const res = selected.value[0]
      deleteItem.value = {
        name: res.registerChannelName,
        platform: res.registerPlatform,
        activeType: active.value === 'channel' ? 0 : 1,
        id: res.id,
        title: '批量删除',
      }
      visibleDelete.value = true
    }

    const handleSubmitExportTemplateDialog = () => {
      ExportTemplateDialog.value = false
      handleRefresh()
    }

    function getLabelText(row) {
      const labelText = worksType.find(item => item.value === row.compositionType)
      return labelText ? labelText.label : '-'
    }

    return {
      active,
      tabsConfig,
      searchConfig: searchConfigFilter,
      ...searchOthers,
      showDialog,
      currItem,
      tableColumns,
      handleRefresh,
      handleAdd,
      handleRemove,
      visibleDelete,
      deleteData,
      deleteItem,
      visibleAdd,
      rowPlatform,
      ExportTemplateDialog,
      handleExport,
      handleSubmitExportTemplateDialog,
      batchDelete,
      selectionChange,
      tableRef,
      getLabelText,
    }
  },
})
</script>

<template>
  <app-view-tow>
    <template #header>
      <div class="flex-vertical gap8" style="width: 100%">
        <TabsPro v-model="active" @tabClick="handleTabChange">
          <TabPanePro v-for="item in tabsConfig" :key="item.key" :label="item.name" :name="item.key" />
        </TabsPro>
        <XwSearch ref="searchRef" :col-span="3" :form-config="searchConfig" @search="search" />
      </div>
    </template>
    <template #main-header>
      <div class="flex-left gap8">
        <v-btn color="primary" @click="handleAdd(0)">
          新增YT
        </v-btn>
        <v-btn color="primary" @click="handleAdd(1)">
          新增FB
        </v-btn>
        <v-btn outlined @click="handleExport()">
          导入
        </v-btn>
        <v-btn outlined @click="batchDelete">
          批量删除
        </v-btn>
      </div>
    </template>

    <super-table ref="tableRef" :columns="tableColumns" :data="pageList" :loading="loading" :row-config="{ height: 65,keyField:'id' }" :checkbox-config="{reserve:true}" @selection-change="selectionChange">
      <template #signPlatform="{ row, index }">
        <icon-platform :key="`signPlatform${index}`" style="transform: translateY(3px)" :platform="row.signPlatform" />
        <span style="line-height: 16px">{{ row.signChannelName }}</span>
      </template>
      <template #playlist="{ row }">
        <div>{{ row.registerPlatform !== 'YouTube' ? row.videoCustomTag || '-' : row.playlist || '-' }}</div>
      </template>
      <template #compositionType="{ row }">
        {{ getLabelText(row) }}
      </template>
      <template #signUrl="{ row }">
        <a
          v-if="row.signUrl"
          :href="row.signUrl"
          target="_blank"
          class="text--link overflow"
          style="text-decoration: none"
        >{{ row.signUrl }}</a>
        <span v-else>-</span>
      </template>
      <template #registerChannelName="{ row }">
        <ToolsBox
          :platform="row.registerPlatform"
          :name="row.registerChannelName"
          :channel-id="row.registerChannelId"
        />
      </template>
      <template #opts="{ row }">
        <span class="app-link" @click="handleRemove(row)"> 删除 </span>
      </template>
    </super-table>
    <template #footer>
      <vl-pagination
        :page-size="pageSize"
        :page-index="page"
        :total="pageLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Delete
      v-if="visibleDelete"
      :delete-data="deleteData"
      :row="deleteItem"
      @close="visibleDelete = false"
      @refresh="handleRefresh"
    />
    <AddData
      v-if="visibleAdd"
      :platform="rowPlatform"
      :active-type="active === 'channel' ? 0 : 1"
      @close="visibleAdd = false"
      @refresh="handleRefresh"
    />

    <ExportTemplate
      :visible="ExportTemplateDialog"
      :active-type="active === 'channel' ? 0 : 1"
      @close="ExportTemplateDialog = false"
      @submit="handleSubmitExportTemplateDialog"
    />
  </app-view-tow>
</template>
<style scoped lang="scss">
::v-deep .tab-content-item-box {
  .tab-pane-pro {
    padding: 0;
  }
}
.form-wrap {
  box-shadow: none !important;
  ::v-deep .form-label {
    width: auto !important;
  }
}
.text--link {
  color: rgba(0, 0, 0, 0.8) !important;
}
</style>
