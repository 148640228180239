<script>
import { ref } from '@vue/composition-api'
import IconPlatform from '@/components/IconPlatform.vue'

export default {
  name: 'NoticeBox',
  components: { IconPlatform },
  props: {
    errorList: {
      type: Array,
      default: () => ([]),
    },
    normalList: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const visible = ref(true)

    function submit() {
      visible.value = false
      if (props.normalList.length > 0) {
        emit('refresh')
      }
    }

    return {
      visible,
      submit,
    }
  },
}
</script>

<template>
  <vl-dialog
    :visible.sync="visible"
    title="提示"
    width="450px"
    top="0"
    @close="$emit('close')"
  >
    <div class="flex-vertical">
      <div class="flex-left gap4 fs14" style="align-items: flex-start; line-height: 22px">
        <v-icon color="#FFA80F" size="18" style="transform: translateY(1%)">
          mdi-information
        </v-icon>
        以下「签约频道和签约套餐」与所填「注册频道」已绑定，请勿重复发起绑定！
      </div>
      <div class="flex-between mt16 fs14 gap8">
        <div class="road-item flex-vertical gap4">
          <div v-for="(item, index) in errorList" :key="index" class="flex-left gap4">
            <IconPlatform :platform="item.signPlatform" />
            <span class="line-clamp1">{{ item.signChannelName }} —— {{ item.servicePageName }}</span>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex-right gap8">
        <v-btn color="primary" @click="submit">
          {{ normalList.length === 0 ? '我知道了' : '我知道了, 只绑定未绑定频道' }}
        </v-btn>
      </div>
    </template>
  </vl-dialog>
</template>

<style scoped lang="scss">
.road-item {
  padding: 12px;
  flex: auto;
  width: 100%;
  min-height: 52px;
  background: #F6F7F8;
  border-radius: 6px;
  color: rgba(0,0,0,0.8);
}

.vl-dialog__wrapper {
  display: flex;
  align-items: center;
}
::v-deep .vl-dialog__body {
  padding: 20px 0 !important;
}
</style>
