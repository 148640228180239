<script>
export default {
  name: 'VlTable',
}
</script>

<template>
  <table class="table">
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<style scoped lang="scss">
.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
