import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { platformOptionsByOverseas, domPlatformOptions, worksType } from '@core/utils/options'
import { getPage } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(20)
  const pageLength = ref(0)
  const active = ref('channel')
  const searchRef = ref()

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    signPlatform: null,
    signChannel: null,
    signWorks: null,
    registerPlatformValue: null,
    registerChannelName: null,
    playlist: null,
    registerChannelId: null,
    compositionType: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VAutocomplete',
      props: 'signPlatformValue',
      placeholder: '签约内容平台',
      label: '签约内容平台',
      items: domPlatformOptions,
      multiple: false,
      itemText: 'label',
      itemValue: 'value',
      active: 'channel',
    },
    {
      element: 'VTextField',
      props: 'signChannelName',
      placeholder: '签约内容频道',
      label: '签约内容频道',
      active: 'channel',
    },
    {
      element: 'VTextField',
      props: 'signChannelName',
      placeholder: '签约内容作品名',
      label: '签约内容作品名',
      active: 'others',
    },
    {
      element: 'VAutocomplete',
      props: 'registerPlatformValue',
      placeholder: '注册平台',
      label: '注册平台',
      items: platformOptionsByOverseas,
      itemText: 'label',
      itemValue: 'value',
      multiple: false,
    }, {
      element: 'VTextField',
      props: 'registerChannelId',
      placeholder: '注册频道/主页ID',
      label: '注册频道/主页ID',
    }, {
      element: 'VTextField',
      props: 'registerChannelName',
      placeholder: '注册频道/主页',
      label: '注册频道/主页',
    },
    {
      element: 'VTextField',
      props: 'playlist',
      placeholder: '视频标签/播放列表',
      label: '视频标签/播放列表',
    },
    {
      element: 'VAutocomplete',
      props: 'compositionType',
      items: worksType,
      placeholder: '作品类型',
      label: '作品类型',
      active: 'others',
      itemText: 'label',
      itemValue: 'value',
      multiple: false,
    },
  ])

  const fetchList = async () => {
    loading.value = true
    try {
      const {
        data: {
          data = [], status, total = null, message: messageText,
        },
      } = await getPage({
        page: page.value,
        pageSize: pageSize.value,
        ...queryData,
        registerChannelId: queryData.registerChannelId ? queryData.registerChannelId.trim() : null,
        type: active.value === 'channel' ? 0 : 1,
      })
      loading.value = false
      if (status === 200) {
        pageList.value = data
        pageLength.value = total
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err.response.data.message)
      loading.value = false
    }
  }

  const search = async params => {
    Object.assign(queryData, params)
    page.value = 1
    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  const handleTabChange = e => {
    active.value = e.name
    page.value = 1
    Object.assign(queryData, queryDataOri)
    searchRef.value.reset()
  }

  onMounted(() => {
    fetchList()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    active,
    searchRef,

    search,
    fetchList,
    handleRefresh,
    handleSizeChange,
    handleCurrentChange,
    handleTabChange,
  }
}
