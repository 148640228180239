<script>
import AutocompleteRemote from '@/views/ways/publishWays/components/AutocompleteRemote.vue'
import { getSignWorks } from '@/views/ways/publishWays/api'
import { required } from '@core/utils/validation'
import OverflowTooltip from '@/components/OverflowTooltip.vue'

export default {
  name: 'Works',
  components: { OverflowTooltip, AutocompleteRemote },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      getSignWorks,
      required,
      servicePackage: new Map(),
    }
  },
  computed: {
    newValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    minusSign(index) {
      const list = [...this.newValue]
      list.splice(index, 1)
      this.newValue = list
    },
    signChange(e, data, index) {
      const datas = this.newValue[index]
      datas.signUrl = data.link
      datas.signPlatform = data.platform
      datas.copyrightHolder = data.copyrightHolder
      datas.signChannelId = data.id
      datas.signChannelName = data.name
      datas.videoCustomTag = data.name
      datas.playlist = data.name
      datas.keyId = e
      if (this.servicePackage.get(e) === undefined) {
        this.servicePackage.set(e, data.servicePageNameList) // 存储当前套餐
      }
      const list = this.newValue.filter(value => value.keyId === e).filter(value => value.servicePageName).map(value => value.servicePageName)
      const newList = this.servicePackage.get(e).filter(item => !list.includes(item))
      datas.servicePageName = newList.length !== 0 ? newList[0] : undefined
    },
    goToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.listContainer
        container.scrollTop = container.scrollHeight
      })
    },
    ServicePageNameChange(ind, item, val) {
      this.newValue.forEach((value, index) => {
        if (item.keyId === value.keyId && val === value.servicePageName && index !== ind) {
          this.newValue[index].servicePageName = undefined
          this.$forceUpdate()
        }
      })
    },

    servicePageNameList(e) {
      return this.servicePackage.get(e)
    },
  },
}
</script>

<template>
  <div ref="listContainer" class="list my16">
    <div v-for="(item, index) in newValue" :key="item._key_" class="list-box">
      <div class="row-title flex-left gap8">
        <v-icon v-if="newValue.length > 1" color="primary" @click="minusSign(index)">
          mdi-minus-circle-outline
        </v-icon>
        签约频道{{ index + 1 }}
      </div>
      <div class="grid-container mt12">
        <vl-form-item label="签约内容作品" required label-width="98">
          <AutocompleteRemote
            :api="getSignWorks"
            :rules="[required]"
            search-key="name"
            allow-repetition
            :select-list="newValue"
            :default-options="{ text: 'name', value: 'id' }"
            @change="(e, data) => signChange( e, data, index)"
          />
        </vl-form-item>
        <vl-form-item label="签约套餐" label-width="98" required>
          <v-autocomplete
            v-model="newValue[index].servicePageName"
            :items="servicePageNameList(newValue[index].keyId)"
            no-data-text="暂无数据"
            :rules="[required]"
            hide-details
            placeholder="签约套餐"
            dense
            outlined
            clearable
            @change="val => ServicePageNameChange(index, item, val)"
          />
        </vl-form-item>
        <vl-form-item label="签约内容链接" label-width="98">
          <div class="simulation-input">
            <div style="height: 40px; width: 225px; padding: 0 10px; font-size: 14px;">
              <overflow-tooltip height="40" width="190" :label="item.signUrl ||'签约内容链接'" />
            </div>
          </div>
        </vl-form-item>

        <vl-form-item label="CP名称" label-width="98">
          <v-text-field
            v-model="item.copyrightHolder"
            style="background-color: #F6F7F8"
            disabled dense outlined hide-details
            placeholder="CP名称"
          />
        </vl-form-item>
      </div>
      <div v-if="newValue.length > 1" class="pt16">
        <v-divider></v-divider>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
::v-deep .vl-form-item {
  .vl-form-item-label {
    font-size: 14px !important;
  }
}

.vl-form-item + .vl-form-item {
  margin-top: 0;
}
.list {
  max-height: 380px;
  min-height: 130px;
  overflow: hidden;
  overflow-y: auto;
}

.list-box {
  .row-title {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0,0,0,0.8);
  }
}
.list-box + .list-box {
  margin-top: 10px;
}
.simulation-input {
  background-color: #F6F7F8;
  height: 40px;
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,0.1);

  ::v-deep .text-truncate{
    color: rgba(19, 17, 32, 0.38) !important;
  }
}
</style>
