export default {
  PAGE: '/publishChannel/page',
  SAVE: '/publishChannel/publish',
  REMOVE: '/publishChannel/delete',
  REMOVEList: '/publishChannel/deleteList',
  SIGN_CHANNEL: '/signChannel/list',
  OVERSEA_CHANNEL: '/channel/list',
  SIGN_WORKS: '/amsComposition/list',
  TEMPLATE_URL: '/publishChannel/getTemplate',
  UPLOAD_TEMPLATE: '/publishChannel/import',
}
