import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import {
  // eslint-disable-next-line import/named
  channelMultipleOptions, operationGroupOptionsByYtnews, operationGroupOptionsByFbnews, CMSNet, CMSName, makeStates, querylevelOptions, channelStates,
  contentClass, homepageStatus, shelveOptions, queryhomepageProperies, compareOptions, operationModeYTOptions,
} from '@core/utils/options'
import { getCategory } from '@/views/audit/clueAudit/api'
import { getPage } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const active = ref('')
  const searchRef = ref()
  const tabsConfig = ref([])
  const categoryOptions = ref([])
  const settingCategory = ref([])

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    channelId: null,
    channelName: null,
    categoryValue: null,
    operationTeamValue: null,
    collectionTypeValue: null,
    profitValue: null,
    isLinked: null,
    contentOwner: null,
    contentTypeValue: null,
    pageAttribute: null,
    isShelve: null,
    copyrightHolder: null,
    subscribe: null,
    compareSubscribe: '大于',
    videoChangeCount: null,
    compareVideoChangeCount: '小于',
    videoCount: null,
    compareVideoCount: '等于',
    pageStatus: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'channelId',
      placeholder: '频道ID',
      label: '频道ID',
      active: 'youtube',
    },
    {
      element: 'VTextField',
      props: 'channelName',
      placeholder: '频道',
      label: '频道',
      active: 'youtube',
    },
    {
      element: 'VTextField',
      props: 'channelId',
      placeholder: '主页ID',
      label: '主页ID',
      active: 'facebook',
    },
    {
      element: 'VTextField',
      props: 'channelName',
      placeholder: '主页',
      label: '主页',
      active: 'facebook',
    },
    {
      element: 'VCascader',
      props: 'categoryValue',
      placeholder: '垂类',
      label: '垂类',
      items: categoryOptions.value,
      multiple: false,
      itemText: 'name',
      itemValue: 'id',
    },

    // 新增
    {
      element: 'VAutocomplete',
      props: 'isLinked',
      placeholder: '与CMS关联',
      label: '与CMS关联',
      items: CMSNet,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'youtube',
    },
    {
      element: 'VAutocomplete',
      props: 'contentOwner',
      placeholder: 'CMS名称',
      label: 'CMS名称',
      items: CMSName,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'youtube',
    },
    {
      element: 'VAutocomplete',
      props: 'pageAttribute',
      placeholder: '运营类型',
      label: '运营类型',
      items: operationModeYTOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'youtube',
    },
    {
      element: 'VAutocomplete',
      props: 'contentTypeValue',
      placeholder: '内容分类',
      label: '内容分类',
      items: contentClass,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'facebook',
    },
    {
      element: 'VAutocomplete',
      props: 'pageAttribute',
      placeholder: '主页属性',
      label: '主页属性',
      items: queryhomepageProperies,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'facebook',
    },

    // 新增结束
    {
      element: 'VAutocomplete',
      props: 'operationTeamValue',
      placeholder: '运营团队',
      label: '运营团队',
      items: operationGroupOptionsByYtnews,
      itemText: 'text',
      itemValue: 'value',
      multiple: false,
      active: 'youtube',
    },
    {
      element: 'VAutocomplete',
      props: 'operationTeamValue',
      placeholder: '运营团队',
      label: '运营团队',
      items: operationGroupOptionsByFbnews,
      itemText: 'text',
      itemValue: 'value',
      multiple: false,
      active: 'facebook',
    },
    {
      element: 'VAutocomplete',
      props: 'collectionTypeValue',
      placeholder: '合集/单开',
      label: '合集/单开',
      items: channelMultipleOptions,
      itemText: 'text',
      itemValue: 'value',
      multiple: false,
    },
    {
      element: 'VAutocomplete',
      props: 'profitValue',
      placeholder: '获利',
      label: '获利',
      items: makeStates,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },

    // 新增
    {
      element: 'VAutocomplete',
      props: 'profitFlag',
      placeholder: '获利变化',
      label: '获利变化',
      items: makeStates,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'youtube',
    },
    {
      element: 'VAutocomplete',
      props: 'incomeScale',
      placeholder: '运营级别',
      label: '运营级别',
      items: querylevelOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',

      // active: 'youtube',
    },
    {
      element: 'VAutocomplete',
      props: 'isShelve',
      placeholder: '搁置',
      label: '搁置',
      items: shelveOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'facebook',
    },
    {
      element: 'VTextField',
      props: 'copyrightHolder',
      placeholder: '合作方名称',
      label: '合作方名称',

      // active: 'youtube',
    },
    {
      element: 'VTextField',
      props: 'subscribe',
      placeholder: '订阅数',
      label: '订阅数',
      items: shelveOptions,
      slotCompare: { props: 'compareSubscribe', initValue: '大于' },
      multiple: false,
      type: 'number',
      active: 'youtube',
    },
    {
      element: 'VTextField',
      props: 'videoChangeCount',
      placeholder: '视频变化数',
      label: '视频变化数',
      items: shelveOptions,
      slotCompare: { props: 'compareVideoChangeCount', initValue: '小于' },
      multiple: false,
      type: 'number',
      active: 'youtube',
    },
    {
      element: 'VTextField',
      props: 'videoCount',
      placeholder: '视频数',
      label: '视频数',
      items: shelveOptions,
      slotCompare: { props: 'compareVideoCount', initValue: '等于' },
      multiple: false,
      type: 'number',
      active: 'youtube',
    },
    {
      element: 'VAutocomplete',
      props: 'pageStatus',
      placeholder: '频道状态',
      label: '频道状态',
      items: channelStates,
      multiple: true,
      itemText: 'text',
      itemValue: 'value',
      active: 'youtube',
      initValue: [1, 2, 3, 4, 5, 6],
    },
    {
      element: 'VAutocomplete',
      props: 'pageStatus',
      placeholder: '主页状态',
      label: '主页状态',
      items: homepageStatus,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: 'facebook',
    },

    // 新增结束
  ])

  // 获取垂类
  const fetchCategory = async () => {
    const {
      data: { data = [], status, message: messageText },
    } = await getCategory()
    if (status === 200) {
      categoryOptions.value = data || []
    } else {
      message.error(messageText)
    }
  }

  const fetchSettingCategory = async () => {
    const {
      data: { data = [], status, message: messageText },
    } = await getCategory(0)
    if (status === 200) {
      settingCategory.value = data || []
    } else {
      message.error(messageText)
    }
  }

  const calPageStatus = computed(() => {
    let pageStatus = []
    if (active.value === 'youtube') {
      pageStatus = queryData.pageStatus === null ? [1, 2, 3, 4, 5, 6] : queryData.pageStatus
    } else {
      pageStatus = queryData.pageStatus === null ? [] : [queryData.pageStatus]
    }

    return pageStatus
  })

  const fetchList = async () => {
    loading.value = true
    const {
      subscribe, compareSubscribe, videoChangeCount, compareVideoChangeCount, videoCount, compareVideoCount, ...others
    } = queryData

    try {
      const {
        data: {
          data = [], status, total, message: messageText,
        },
      } = await getPage({
        page: page.value,
        pageSize: pageSize.value,
        ...others,
        subscribe: {
          digit: subscribe,
          symbol: compareOptions.find(x => x.value === compareSubscribe).label,
        },
        videoCount: {
          digit: videoCount,
          symbol: compareOptions.find(x => x.value === compareVideoCount).label,
        },
        differenceValue: {
          digit: videoChangeCount,
          symbol: compareOptions.find(x => x.value === compareVideoChangeCount).label,
        },
        type: active.value === 'youtube' ? 0 : 1,
        pageStatus: calPageStatus.value,
      })
      loading.value = false
      if (status === 200) {
        pageList.value = data
        pageLength.value = total
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err.response.data.message)
      loading.value = false
    }
  }

  const search = async params => {
    Object.assign(queryData, params)
    page.value = 1
    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  const handleTabChange = tabItem => {
    active.value = tabItem.key
    page.value = 1
    Object.assign(queryData, queryDataOri)
    searchRef.value.reset()
  }

  onMounted(() => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    const tabss = []
    const roleList1 = ['超级管理员', '研发管理员', '总编室管理员', 'YT频道管理员', '客服', '商务总监']
    const roleList2 = ['超级管理员', '研发管理员', '总编室管理员', 'FB主页管理员', 'FB频道管理员', '客服', '商务总监']
    userRole.every(item => {
      if (roleList1.includes(item)) {
        tabss.push(
          {
            name: 'YouTube',
            key: 'youtube',
          },
        )
        // eslint-disable-next-line newline-before-return
        return false
      }
      // eslint-disable-next-line newline-before-return
      return true
    })

    userRole.every(item => {
      if (roleList2.includes(item)) {
        tabss.push(
          {
            name: 'Facebook',
            key: 'facebook',
          },
        )
        // eslint-disable-next-line newline-before-return
        return false
      }
      // eslint-disable-next-line newline-before-return
      return true
    })

    if (tabss.length > 0) {
      tabsConfig.value = tabss
      active.value = tabss[0].key
    }

    fetchList()
    fetchCategory()
    fetchSettingCategory()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    active,
    searchRef,
    tabsConfig,
    categoryOptions,
    settingCategory,

    search,
    fetchList,
    handleRefresh,
    handleSizeChange,
    handleCurrentChange,
    handleTabChange,
  }
}
