import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 获取线索审核列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {platform} 平台
 * @param {channelName} 频道
 * @param {field} 字段
 * @param {mcnOrg} 机构
 * @param {source} 来源
 * @param {auditStatus} 审核状态
 * @param {auditResult} 审核结果
 */

export const getPage = params => {
  const { activeItem, ...others } = params
  let oUrl = url.PAGE

  if (activeItem.key !== 'channel') {
    oUrl = url.COMPOSITION_PAGE
    others.typeValue = activeItem.value
  }

  return axios.get(prefix + oUrl, { params: others })
}

/**
 * 获取待审核数
 */
export const getAuidtCount = () => axios.post(prefix + url.AUDIT_COUNT)

/**
 * 审核
 * @param {auditStatus} 审核状态
 * @param {auditResult} 审核结果
 * @param {id} id
 * @param {channelLevel} 频道评级
 * @param {rejectReasonValue} 拒绝原因
 * @param {type} 视听,图文,音乐
 * @param {channelType} 频道类型
 */
export const saveAudit = params => {
  const { activeItem, ...others } = params
  let oUrl = url.SAVE_AUDIT

  if (activeItem.key !== 'channel') {
    oUrl = url.COMPOSITION_AUDIT
    others.typeValue = activeItem.value
  }

  return axios.post(prefix + oUrl, others)
}

/**
 * 导出
 */
export const getExport = params => {
  const { activeItem, ...others } = params
  let oUrl = url.EXPORT

  if (activeItem.key !== 'channel') {
    oUrl = url.EXPORT_COMPOSITION
    others.typeValue = activeItem.value
  }

  return axios.post(prefix + oUrl, others, { responseType: 'blob' })
}

/**
 * 获取垂类
 * @param { Number } 1: 获取所有垂类, 0: 筛选掉未启动的垂类
 */
export const getCategory = (type = 1) => axios.get(`${prefix}${url.CATEGORY}?fullData=${type}`)

/**
 * 保存
 */
export const save = params => {
  const { activeItem, ...others } = params
  let oUrl = url.SAVE

  if (activeItem.key !== 'channel') {
    oUrl = url.COMPOSITION_SAVE
    others.typeValue = activeItem.value
  }

  return axios.post(prefix + oUrl, others)
}

// 导入
export const clueImport = params => {
  const { activeItem, form } = params
  let oUrl = url.IMPORT

  if (activeItem.key !== 'channel') {
    oUrl = url.IMPORT_COMPOSITION
  }

  return axios.post(prefix + oUrl, form)
}

// 导出失败Excel
export const exportFailure = params => {
  const { activeItem, fileId } = params
  let oUrl = url.EXPORT_FAILURE

  if (activeItem.key !== 'channel') {
    oUrl = url.EXPORT_FAILURE_COMPOSITION
  }

  return axios.get(`${prefix}${oUrl}?fileId=${fileId}`, {
    responseType: 'blob',
  })
}
