import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-nav__wrapper",class:[_vm.scrollable ? 'is-scrollable' : '']},[(_vm.scrollable)?_c('div',{staticClass:"tab-nav-scroll-btn left",on:{"click":function($event){$event.stopPropagation();return _vm.scrollPrev.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1):_vm._e(),(_vm.scrollable)?_c('div',{staticClass:"tab-nav-scroll-btn right",on:{"click":function($event){$event.stopPropagation();return _vm.scrollNext.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1):_vm._e(),_c('div',{ref:"navScroll",staticClass:"tab-nav_scroll"},[_c('div',{ref:"nav",staticClass:"tab-nav-item-box",class:[_vm.type === 'succinct' ? 'succinct' : ''],style:(_vm.navStyle)},_vm._l((_vm.tabItemArr),function(tabItem,index){return _c('div',{key:index,class:[
          'tab-nav-item',
          tabItem.name === _vm.activeName ? 'highLight' : '',
          tabItem.disabled ? 'isForbiddenItem' : '',
          _vm.type === 'succinct' ? 'succinct-item' : ''
        ],on:{"click":function($event){return _vm.changeActiveName(tabItem)}}},[_vm._v(" "+_vm._s(tabItem.label)+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }