<script>
import { ref, reactive, watch } from '@vue/composition-api'
import { useValidation } from '@/hooks/useValidation'
import { debounce } from '@prettyy/utils'
import AutocompleteRemote from '@/views/ways/publishWays/components/AutocompleteRemote.vue'
import { getUUID } from '@core/utils'
import { useMessage } from '@/hooks/useMessage'
import { getRegisterChannel, getSignWorks, saveWays } from '../api'

export default {
  name: 'AddChannel',
  components: { AutocompleteRemote },
  props: {
    platform: {
      type: Number,
      default: () => (0), // 0为youtube 1为facebook
    },
  },
  setup(props, { emit }) {
    const visible = ref(true)
    const formRef = ref()
    const register = ref(false)
    const { message } = useMessage()
    const listContainer = ref()
    const search = ref('')
    const formData = reactive({
      channelId: null, // 注册id
      signList: [
        {
          _key_: getUUID(),
          signPlatform: undefined, // 签约作品平台
          copyrightHolder: undefined, // CP名称
          signChannelId: undefined, // 签约作品id
          signChannelName: undefined, // 签约作品名称
          signUrl: undefined, // 签约链接
          registerPlatform: props.platform, // 注册平台
          videoCustomTag: undefined, // 视频自定义标签
          playlist: undefined, // 播放列表
          type: 1,
        },
      ],
    })
    const channelList = ref([])
    const { required } = useValidation()
    function getRegisterChannelList(e) {
      if (channelList.value.find(item => item.text === e)) return
      register.value = true
      getRegisterChannel({ type: props.platform, channelId: e }).then(({ data }) => {
        if (data.status === 200) {
          register.value = false
          channelList.value = (data.data || []).map(item => ({ text: `${item.channelName}(${item.id})`, value: item.id, ...item }))
          if (channelList.value.length === 0) message.warning('暂未查询到相关内容')
        }
      })
    }
    const searchQuery = debounce(500, getRegisterChannelList)
    watch(search, val => val && searchQuery(val))

    function signChange(e, data, index) {
      const datas = formData.signList[index]
      datas.signUrl = data.link
      datas.signPlatform = data.platform
      datas.copyrightHolder = data.copyrightHolder
      datas.signChannelId = data.id
      datas.signChannelName = data.name
      datas.videoCustomTag = data.name
      datas.playlist = data.name
    }
    function submit() {
      formRef.value.asyncValidate().then(() => {
        const findData = channelList.value.find(item => item.value === formData.channelId)
        const data = {
          registerChannelId: formData.channelId,
          publishChannelVOList: formData.signList.map(item => ({
            ...item,
            registerChannelId: formData.channelId,
            registerChannelName: findData.channelName,
          })),
        }

        saveWays(data).then(res => {
          if (res.data.status === 200) {
            message.success('新增成功')
            emit('refresh')
            visible.value = false
          } else {
            message.error(res.data.message)
          }
        })
      })
    }

    return {
      visible,
      formRef,
      formData,
      required,
      channelList,
      search,
      register,
      signChange,
      listContainer,
      submit,
      getSignWorks,
    }
  },
}
</script>

<template>
  <vl-dialog
    :visible.sync="visible"
    :title="`新增${platform ? 'FB' : 'YT'}发布通道`"
    width="690px"
    top="0"
    @close="$emit('close')"
  >
    <vl-form ref="formRef">
      <div class="grid-container py8" style="background: #F6F7F8; border-radius: 4px;">
        <vl-form-item label="注册主页：" required label-width="96">
          <v-autocomplete
            v-model="formData.channelId"
            :rules="[required]"
            :loading="register"
            hide-details
            :items="channelList"
            :search-input.sync="search"
            placeholder="请输入后选择"
            dense
            hide-no-data
            outlined
            clearable
          />
        </vl-form-item>
      </div>
      <div ref="listContainer" class="list my16">
        <div v-for="(item, index) in formData.signList" :key="item._key_" class="list-box">
          <div class="row-title flex-left gap8">
            签约内容作品
          </div>
          <div class="grid-container mt12">
            <vl-form-item label="签约内容作品" required label-width="100">
              <AutocompleteRemote
                :api="getSignWorks"
                :rules="[required]"
                search-key="name"
                allow-repetition
                :default-options="{ text: 'name', value: 'id' }"
                @change="(e, data) => signChange( e, data, index)"
              />
            </vl-form-item>
            <vl-form-item label="签约内容链接" label-width="100">
              <v-text-field
                v-model="item.signUrl"
                style="background-color: #F6F7F8"
                disabled dense outlined hide-details
                placeholder="签约内容链接"
              />
            </vl-form-item>

            <vl-form-item label="CP名称" label-width="90">
              <v-text-field
                v-model="item.copyrightHolder"
                style="background-color: #F6F7F8"
                disabled dense outlined hide-details
                placeholder="CP名称"
              />
            </vl-form-item>
          </div>
          <div class="pt16" />
        </div>
      </div>
    </vl-form>

    <template #footer>
      <div class="flex-right gap8">
        <v-btn outlined @click="visible = false">
          取消
        </v-btn>
        <v-btn color="primary" @click="submit">
          确认
        </v-btn>
      </div>
    </template>
  </vl-dialog>
</template>

<style scoped lang="scss">
.vl-dialog__wrapper {
  display: flex;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

::v-deep .vl-form-item {
  .vl-form-item-label {
    font-size: 14px !important;
  }
}

.vl-form-item + .vl-form-item {
  margin-top: 0;
}
::v-deep .vl-dialog__body {
  padding: 20px 0 !important;
}
.list {
  max-height: 380px;
  overflow: hidden;
  overflow-y: auto;
}

.list-box {
  .row-title {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0,0,0,0.8);
  }
}
.list-box + .list-box {
  margin-top: 10px;
}
</style>
