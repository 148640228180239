<script>
import Title from '@/components/Title.vue'
import BasicDrawer from '@/components/BasicDrawer.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import { reactive, ref } from '@vue/composition-api'
import VlTable from '@/components/tableForm/VlTable.vue'
import VlTableItem from '@/components/tableForm/VlTableItem.vue'
import OverflowTooltip from '@/components/OverflowTooltip.vue'
import SuperTable from '@/components/SuperTable/index.vue'
import dayjs from 'dayjs'
import PlatformIcon from '@/components/PlatformIcon.vue'
import { getHandover } from '@/api/accountHomePageAdd'

export default {
  name: 'ViewDeliveryReceipt',
  components: {
    OverflowTooltip,
    PlatformIcon,
    SuperTable,
    VlTableItem,
    VlTable,
    TabPanePro,
    TabsPro,
    BasicDrawer,
    Title,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const activeName = ref('basic')
    const formData = reactive({
      supplyFrequency: undefined,
      operationRequirement: undefined,
      publishRequirement: undefined,
      createdUser: undefined,
      updatedAt: undefined,
      deliveryReceiptScopes: [],
    })
    function close() {
      emit('close')
    }
    const tableColumns = [
      { props: 'channelName', label: '频道/作品名', minWidth: 480 },
      { props: 'source', label: '频道来源', width: 146 },
    ]
    const tableData = ref([])

    function init() {
      if (props.row.num) {
        getHandover({ num: props.row.num }).then(({ data }) => {
          if (data.status === 200) {
            tableData.value = data.data.channelVos
            Object.assign(formData, data.data)
            formData.createdAt = props.row.updatedAt
          }
        })
      }
    }
    function dateFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
      return dayjs(date).format(format)
    }

    init()

    return {
      activeName,
      tableColumns,
      tableData,
      formData,
      close,
      dateFormat,
    }
  },
}
</script>

<template>
  <BasicDrawer
    :title="row.num"
    width="750px"
    @close="close"
  >
    <template #icon>
      <img src="@/assets/icon/contract.png" style="width: 20px; height: 20px" alt="">
    </template>
    <template #main-header>
      <div class="flex-left gap16 system">
        <span>交接人：{{ formData.createdUser || '-' }}</span>
        <v-divider vertical />
        <span>
          交接时间： {{ formData.createdAt ? dateFormat(formData.createdAt) : '-' }}
        </span>
        <slot name="append" />
      </div>
    </template>

    <div class="delivery-body px20 py12">
      <div class="delivery-body-car pt12">
        <TabsPro v-model="activeName" type="succinct" :show-header="false">
          <TabPanePro label="基础信息" name="basic">
            <Title title="频道/作品信息" class="mt12 mb16" />
            <div style="border: 1px solid #e8eaec; border-right: none; border-bottom: none;">
              <SuperTable
                :min-height="44"
                max-height="280px"
                :is-height-auto="false"
                border-show
                :border="true"
                :columns="tableColumns"
                :data="tableData"
              >
                <template #channelName="{ row }">
                  <PlatformIcon v-model="row.platform" :show-text="false" :text="row.channelName" />
                </template>
                <template #empty>
                  <div>暂无数据</div>
                </template>
              </SuperTable>
            </div>
            <Title title="账号信息" class="my18 mt30" />
            <VlTable>
              <template v-for="(item, index) in formData.deliveryReceiptScopes">
                <VlTableItem :key="index" :label="item.platform === 'YouTube' ? 'YT账号归属方' : 'FB账号归属方'">
                  <div class="flex-vertical gap8 py6" style="width: 100%">
                    <div>{{ item.accountOwnership }}</div>
                    <template v-if="item.accountOwnership === '客户自带'">
                      <div class="flex-left gap5" style="width: 100%;">
                        <span class="shrink0">(自带账号链接:</span>
                        <div v-if="item.selfChannelLink" class="flex-left link-box" style="height: 20px; flex: auto">
                          <overflow-tooltip is-link :link="item.selfChannelLink || '-'" label="" />)
                        </div>
                        <span v-else>-)</span>
                      </div>
                      <div class="flex-left gap5" style="width: 100%">
                        <span class="shrink0">(账号获利情况:</span>
                        <span v-if="![null, undefined].includes(item.isProfit)">{{ item.isProfit === 1 ? '是' : '否' }})</span>
                        <span v-else>-)</span>
                      </div>
                    </template>
                  </div>
                </VlTableItem>
              </template>
              <VlTableItem label="客户供片频率" :text="formData.supplyFrequency ? `${formData.supplyFrequency} 条/月`: '-'" />
            </VlTable>
            <Title title="运作要求" class="mt30 mb18" />
            <VlTable>
              <VlTableItem label-class="py12" label="特殊运作要求及处理方式" :text="formData.operationRequirement || '-'" />
              <VlTableItem label-class="py12" label="对播放量/更新频次/收益要求" :text="formData.publishRequirement || '-'" />
            </VlTable>
          </TabPanePro>
        </TabsPro>
      </div>
    </div>

    <template #footer>
      <v-btn outlined @click="close">
        关闭
      </v-btn>
    </template>
  </BasicDrawer>
</template>

<style scoped lang="scss">
.delivery-body {
  flex: auto;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #F6F7F8;

  &-car {
    border-radius: 6px;
    background-color: #fff;
    padding: 20px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
::v-deep .table-item {
  color: rgba(0,0,0,0.5);
}
::v-deep .table-value {
  .app-link {
    color: #2381FF !important;
  }
}

.shrink0 {
  flex-shrink: 0;
}

.link-box {
  ::v-deep .app-link {
    color: rgba(0,0,0,0.8) !important;
  }

  &:hover {
    ::v-deep .app-link {
      color: #2381FF !important;
    }
  }
}
</style>
