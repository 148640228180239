const tableCols = [
  {
    text: '',
    sortable: false,
    type: 'checkbox',
    value: 'check',
    minWidth: 50,
    fixed: 'left',
    align: 'left',
  },
  {
    text: '频道',
    sortable: false,
    value: 'channelName',
    active: ['channel'],
    minWidth: 200,
    slots: 'channelName',
    fixed: 'left',
    align: 'left',
  },
  {
    text: '平台',
    sortable: false,
    value: 'platform',
    active: ['channel'],
    minWidth: 100,
    align: 'left',
    slots: 'platform',
  },
  {
    text: '作品名',
    sortable: false,
    value: 'name',
    active: ['video', 'image', 'audio', 'films'],
    minWidth: 120,
    fixed: 'left',
    slots: 'name',
  },
  {
    text: '作品规格',
    sortable: false,
    value: 'specification',
    active: ['video', 'image', 'audio'],
    minWidth: 120,
  },
  {
    text: '时长（分钟）',
    sortable: false,
    value: 'duration',
    active: ['video', 'audio'],
    algin: 'right',
    minWidth: 120,
  },
  {
    text: '链接',
    sortable: false,
    value: 'link',
    minWidth: 200,
    slots: 'link',
    active: ['video', 'image', 'audio'],
  },
  {
    text: 'MCN机构',
    sortable: false,
    value: 'mcnOrg',
    active: ['channel'],
    minWidth: 100,
  },
  {
    text: '频道类型',
    sortable: false,
    value: 'channelType',
    active: ['channel'],
    minWidth: 100,
  },
  {
    text: '频道来源',
    sortable: false,
    value: 'source',
    active: ['channel'],
    minWidth: 100,
  },
  {
    text: '线索备注',
    sortable: false,
    value: 'clueRemark',
    minWidth: 150,
  },
  {
    text: '审核状态',
    sortable: false,
    value: 'auditStatus',
    minWidth: 100,
    align: 'center',
    slots: 'auditStatus',
  },
  {
    text: '审核结果',
    sortable: false,
    value: 'auditResult',
    minWidth: 100,
    slots: 'auditResult',
    align: 'center',
  },
  {
    text: '频道评级',
    sortable: false,
    value: 'level',
    minWidth: 100,
    active: ['video', 'image', 'audio', 'films'],
    slots: 'level',
  },
  {
    text: '复审原因',
    sortable: false,
    value: 'reviewReason',
    minWidth: 150,
    slots: 'reviewReason',
  },
  {
    text: '创建时间',
    sortable: false,
    value: 'createdAt',
    minWidth: 150,
  },
  {
    text: '频道评级',
    sortable: false,
    value: 'channelLevel',
    minWidth: 100,
    active: ['channel'],
    slots: 'channelLevel',
  },
  {
    text: '审核人',
    sortable: false,
    value: 'auditedUserName',
    minWidth: 100,
    slots: 'auditedUserName',
  },
  {
    text: '审核时间',
    sortable: false,
    value: 'auditedAt',
    minWidth: 150,
    slots: 'auditedAt',
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    width: 100,
    fixed: 'right',
  },
]

export default tableCols
