<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Title',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div
    v-show="title"
    class="title-line fs16"
  >
    {{ title }}
    <span class="subTitle">{{ subTitle }}</span>

    <slot name="append" />
  </div>
</template>

<style scoped lang='scss'>
.title-line {
  position: relative;
  margin-left: 10px;
  color: rgba(0,0,0,.8);
  font-weight: bold;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -13px;
    width: 5px;
    height: 75%;
    background-color: var(--v-primary-base);
  }

  .subTitle{
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.4);
  }
}
</style>
