<script>
import {
  computed, defineComponent, ref,
} from '@vue/composition-api'
import { convertSecondsToHMS } from '@core/utils'
import dayjs from 'dayjs'
import ViewDeliveryReceipt from '@/components/ViewDeliveryReceipt.vue'
import Timeline from './components/Timeline.vue'
import tableCols from './tableCols'
import useSearch from './useSearch'
import Setting from './components/Setting.vue'

export default defineComponent({
  components: {
    ViewDeliveryReceipt,
    Timeline,
    Setting,
  },
  setup() {
    const showDialog = ref(false)
    const currItem = ref({})
    const showTimeline = ref(false)
    const showSetting = ref(false)
    const visibleView = ref(false)
    const activeRow = ref({})
    const {
      queryDataOri, fetchList, searchConfig, queryData, active, ...searchOthers
    } = useSearch()

    const tabsConfig = computed(() => [
      {
        name: '频道',
        key: 'channel',
        value: -1,
      },
      {
        name: '视听作品',
        key: 'video',
        value: 0,
      },
      {
        name: '影视作品',
        key: 'films',
        value: 5,
      },
      {
        name: '图文作品',
        key: 'image',
        value: 1,
      },
      {
        name: '音乐作品',
        key: 'audio',
        value: 2,
      },
    ])

    // 分tab处理table和search配置
    const tableColsFilter = computed(() => tableCols.filter(x => !x.active || x.active.includes(active.value)))
    const searchConfigFilter = computed(() => searchConfig.value.filter(x => !x.active || x.active.includes(active.value)))

    const handleAudit = row => {
      showDialog.value = true
      currItem.value = row
    }

    const handleTimeline = row => {
      showTimeline.value = true
      currItem.value = row
    }

    const handleSetting = row => {
      showSetting.value = true
      currItem.value = row
    }

    function openView(row) {
      visibleView.value = true
      activeRow.value = { ...row, num: row.deliveryReceiptNum }
    }

    return {
      tabsConfig,
      tableCols: tableColsFilter,
      searchConfig: searchConfigFilter,
      currItem,
      showDialog,
      ...searchOthers,
      active,
      showTimeline,
      convertSecondsToHMS,
      dayjs,
      showSetting,
      handleAudit,
      handleTimeline,
      handleSetting,
      visibleView,
      activeRow,
      openView,
    }
  },
})
</script>

<template>
  <AppView>
    <template #header>
      <div style="width: 100%">
        <XwTabs
          :tabs="tabsConfig"
          :active="active"
          :is-loading="loading"
          @change="handleTabChange"
        ></XwTabs>
        <div style="padding: 12px 0">
          <!--搜索区域-->
          <XwSearch
            ref="searchRef"
            :form-config="searchConfig"
            @search="search"
          />
        </div>
      </div>
    </template>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <XwTable
      ref="tableRef"
      :columns="tableCols"
      :rows="pageList"
      :row-config="{height: 80}"
      height="auto"
    >
      <template #video="{row}">
        <div
          class="video-box"
        >
          <div class="video-img">
            <v-img
              :src="row.img"
              width="105"
              height="59"
            />
            <div
              v-if="row.duration"
              class="video-duration"
            >
              {{ convertSecondsToHMS(row.duration) }}
            </div>
          </div>
          <div class="video-info ml-2">
            <div
              class="video-title"
              :title="row.title"
            >
              {{ row.title }}
            </div>
            <div class="video-size">
              {{ row.videoId }}
            </div>
          </div>
        </div>
      </template>
      <template #channelName="{row}">
        <div class="video-channel">
          <XwPlatform
            v-if="row.platform"
            :platform="row.platform"
            :show-name="false"
          ></XwPlatform>
          {{ row.channelName }}
        </div>
      </template>
      <template #deliveryReceiptNum="{row}">
        <span v-if="row.deliveryReceiptNum" class="text--link" @click="openView(row)">{{ row.deliveryReceiptNum }}</span>
        <span v-else>-</span>
      </template>
      <template #time="{row}">
        <div>
          创建时间：{{ row.publishedAt || '-' }}<br />
          获取时间：{{ row.createdAt || '-' }}
        </div>
      </template>
      <template #authorizeTime="{row}">
        <div v-if="row.authorizeStart && row.authorizeEnd">
          {{ dayjs(row.authorizeStart).format('YYYY.MM.DD') }} - {{ dayjs(row.authorizeEnd).format('YYYY.MM.DD') }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #registerPlatform="{row}">
        <div
          v-if="row.registerPlatform && row.registerPlatform.length"
          class="current-register"
        >
          <div>
            <div
              v-for="(item,index) in row.registerPlatform"
              v-show="index < 3"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-if="row.registerPlatform.length > 3"
            class="more"
          >
            <v-tooltip right>
              <template
                #activator="{ on, attrs }"
              >
                <span
                  v-bind="attrs"
                  class="text--link pl10"
                  v-on="on"
                >更多</span>
              </template>
              <div>
                <div
                  v-for="(item, idx) in row.registerPlatform"
                  :key="idx"
                >
                  {{ item }}
                </div>
              </div>
            </v-tooltip>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #opts="{row}">
        <a
          v-if="active === 'channel'"
          @click="handleTimeline(row)"
        >
          时间线
        </a>
        <a
          v-if="active !== 'channel'"
          @click="handleSetting(row)"
        >
          设置
        </a>
      </template>
    </XwTable>

    <template #footer>
      <new-pagination
        :page-index="page"
        :page-size="pageSize"
        :total="pageLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
    <!--审核-->
    <Audit
      v-if="showDialog"
      v-model="showDialog"
      :active-item="activeItem"
      :curr-item="currItem"
      @success="handleRefresh"
    ></Audit>
    <!--时间轴-->
    <Timeline
      v-if="showTimeline"
      v-model="showTimeline"
      :curr-item="currItem"
      type="homePage"
    ></Timeline>
    <!--设置垂类-->
    <Setting
      v-if="showSetting"
      v-model="showSetting"
      :curr-item="currItem"
      :category-options="settingCategory"
      @success="handleRefresh"
    />

    <ViewDeliveryReceipt v-if="visibleView" :row="activeRow" @close="visibleView = false" />
  </AppView>
</template>
<style lang="scss" scoped>
.audit-result {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.video{
  &-box{
    height: 60px;
    display: flex;
    text-decoration: none;
  }
  &-preview{
    height: 60px;
    height: 105px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
  &-duration{
    position: absolute;
    right: 0;
    top: 0;
    min-width: 40px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
    background: rgba(0,0,0, 0.5);
    font-size: 12px;
    color: #fff;
    z-index: 1;
  }
  &-info{
    line-height: 20px;
  }
  &-title{
    height: 40px;
    line-height: 20px;
    color: rgba(0,0,0,0.88);
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 200px;
  }
  &-size{
    color: rgba(0,0,0,0.4);
  }
  &-img{
    width: 105px;
    height: 59px;
    background-color: #ddd;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
  &-channel{
    display: flex;
    align-items: center;
  }
}
.current-register{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
</style>
