import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppView',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('XwSearch',{ref:"searchRef",attrs:{"col-span":3,"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1)]},proxy:true},{key:"main-header",fn:function(){return [_c('div',{staticStyle:{"padding":"6px 0"}},[_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":_vm.handleExport}},[_vm._v(" 导出 ")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})]},proxy:true}])},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList,"height":"auto"},scopedSlots:_vm._u([{key:"channelName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"overflow"},[_c('a',{staticClass:"text--link",staticStyle:{"text-decoration":"none"},attrs:{"href":row.link,"target":"_blank"}},[_vm._v(_vm._s(row.channelName || '-'))])])]}},{key:"platform",fn:function(ref){
var row = ref.row;
return [_c('Platform',{attrs:{"platform":row.platform}})]}},{key:"contractStatus",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"status-item",class:[row.contractStatus === '解约' ? 'status-fail' : '']},[_vm._v(" "+_vm._s(row.contractStatus)+" ")])]}},{key:"deliveryReceiptNum",fn:function(ref){
var row = ref.row;
return [(row.deliveryReceiptNum)?_c('span',{staticClass:"text--link",on:{"click":function($event){return _vm.openView(row)}}},[_vm._v(_vm._s(row.deliveryReceiptNum))]):_c('span',[_vm._v("-")])]}},{key:"authorizePeriod",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.authorizeStart && _vm.dayjs(row.authorizeStart).format('YYYY.MM.DD'))+" - "+_vm._s(row.authorizeEnd && _vm.dayjs(row.authorizeEnd).format('YYYY.MM.DD'))+" ")])]}},{key:"ways",fn:function(ref){
var row = ref.row;
return [(row.currentChannels && row.currentChannels.length)?_c('div',{staticClass:"current-channel"},[_c('div',_vm._l((row.currentChannels),function(item,idx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(idx < 2),expression:"idx < 2"}],key:idx,staticClass:"flex-left"},[_c('Platform',{attrs:{"platform":_vm.getPlatform(item || '').platform,"show-name":false}}),_c('span',[_vm._v(_vm._s(_vm.getPlatform(item || '').content))])],1)}),0),(row.currentChannels.length > 2)?_c('div',{staticClass:"more"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--link pl10"},'span',attrs,false),on),[_vm._v("更多")])]}}],null,true)},[_c('div',{staticClass:"flex-vertical gap6"},_vm._l((row.currentChannels),function(item,idx){return _c('div',{key:idx,staticClass:"flex-left"},[_c('Platform',{attrs:{"platform":_vm.getPlatform(item || '').platform,"show-name":false}}),_c('span',[_vm._v(_vm._s(_vm.getPlatform(item || '').content))])],1)}),0)])],1):_vm._e()]):_c('div',[_vm._v(" - ")])]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.handleSetting(row)}}},[_vm._v(" 设置 ")]),_c('a',{on:{"click":function($event){return _vm.handleTimeline(row)}}},[_vm._v(" 时间线 ")])]}}])}),(_vm.showTimeline)?_c('Timeline',{attrs:{"curr-item":Object.assign({}, _vm.currItem, {id: _vm.currItem.id}),"type":"signChannel"},model:{value:(_vm.showTimeline),callback:function ($$v) {_vm.showTimeline=$$v},expression:"showTimeline"}}):_vm._e(),(_vm.showSetting)?_c('Setting',{attrs:{"curr-item":_vm.currItem,"category-options":_vm.settingCategory},on:{"success":_vm.handleRefresh},model:{value:(_vm.showSetting),callback:function ($$v) {_vm.showSetting=$$v},expression:"showSetting"}}):_vm._e(),(_vm.visibleView)?_c('ViewDeliveryReceipt',{attrs:{"row":_vm.activeRow},on:{"close":function($event){_vm.visibleView = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }