<script>
import { defineComponent, ref, watch, nextTick, onMounted } from '@vue/composition-api'
import { Input, Tree } from 'element-ui'
import { debounce } from '@prettyy/utils'
import { CATEGORY_TYPE } from '../constants'

export default defineComponent({
  components: {
    Input,
    Tree,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    currentNodeKey: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const TreeRef = ref(null)
    const filterText = ref(null)
    const treeData = ref(props.data)
    const expandedKeys = ref([])
    const tagRef = ref(null)
    const treeContainerHeight = ref(0)

    const handleClickNode = (data, node) => {
      emit('click', data, node)
    }

    const isActive = (data, value) => {
      if (!value || value === '' || (value && value.trim() === '')) return false

      const { label } = data

      return label.indexOf(value) !== -1
    }

    const filterTreeData = (list, value) => {
      if (!list || list.length === 0) return []

      if (!value || value === '' || (value && value.trim() === '')) return list

      const result = []

      list.forEach(i => {
        const { children } = i

        let filterChildren = []
        if (children && children.length !== 0) {
          filterChildren = filterTreeData(children, value)

          if (filterChildren.length !== 0) {
            result.push({
              ...i,
              children: filterChildren,
              isExpand: true,
            })
          }
        }

        if (isActive(i, value) && filterChildren.length === 0) {
          result.push({
            ...i,
          })
        }
      })

      return result
    }

    const getExpandedKeys = (list, val) => {
      if (!list || list.length === 0) return []

      if (!val || val === '' || (val && val.trim() === '')) return []

      const result = []

      list.forEach(i => {
        const childrenId = getExpandedKeys(i.children, val)

        if (i.isExpand) {
          result.push(i.id, ...childrenId)
        }
      })

      return result
    }

    const searchKeyValueTree = debounce(200, val => {
      const data = filterTreeData(props.data, val)

      treeData.value = data

      console.log('getExpandedKeys', getExpandedKeys(data, val))

      expandedKeys.value = getExpandedKeys(data, val)
    })

    const handleClickCreate = (data, node) => {
      emit('create', data, node)
    }

    const handleClickDeleted = (data, node) => {
      emit('deleted', data, node)
    }

    const handleClickEdit = (data, node) => {
      emit('edit', data, node)
    }

    /**
     * 平铺所有子集
     * @param list
     */
    const getAllChildrenData = list => {
      if (!list || list.length === 0) return []

      const result = []

      list.forEach(i => {
        if (i.children && i.children.length > 0) {
          result.push(...getAllChildrenData(i.children))
        }

        result.push(i)
      })

      return result
    }

    /**
     * 折叠功能，需要折叠所有子集
     * @param data
     */
    const handleCollapseNode = data => {
      const dataAndDataChildrenIds = getAllChildrenData([data]).map(i => i.id)

      expandedKeys.value = expandedKeys.value.filter(i => !dataAndDataChildrenIds.includes(i))
    }

    const handleExpandNode = data => {
      expandedKeys.value.push(data.id)
    }

    const setTreeContainerHeight = () => {
      treeContainerHeight.value = window.innerHeight - tagRef.value.getBoundingClientRect().top - 40
    }

    const createLabelHtml = string => {
      const result = string.replaceAll(
        filterText.value,
        `<span style='color: rgb(145, 85, 253);'>${filterText.value}</span>`,
      )

      return result
    }

    watch(filterText, val => {
      searchKeyValueTree(val)
    })

    watch(
      () => props.data,
      () => {
        treeData.value = props.data
      },
    )

    watch(
      () => props.currentNodeKey,
      () => {
        TreeRef.value.setCurrentKey(props.currentNodeKey)
      },
    )

    watch(
      () => treeData.value,
      () => {
        nextTick(() => {
          TreeRef.value.setCurrentKey(props.currentNodeKey)
        }, 300)
      },
    )

    onMounted(() => {
      setTreeContainerHeight()
    })

    return {
      tagRef,
      treeContainerHeight,
      TreeRef,
      filterText,
      treeData,
      createLabelHtml,
      handleClickNode,
      expandedKeys,
      CATEGORY_TYPE,

      handleClickCreate,
      handleClickDeleted,
      handleClickEdit,
      handleExpandNode,
      handleCollapseNode,
    }
  },
})
</script>

<template>
  <div class="searchTreeContainer">
    <div class="searchInputWrap">
      <Input v-model="filterText" prefix-icon="el-icon-search" placeholder="请输入垂类名称" clearable />
    </div>
    <slot name="top" />
    <div ref="tagRef" class="tag" />
    <div class="treeWrap" :style="`height: ${treeContainerHeight}px`" >
      <Tree
        ref="TreeRef"
        icon-class="treeIcon"
        :expand-on-click-node="false"
        highlight-current
        :data="treeData"
        node-key="id"
        :default-expanded-keys="expandedKeys"
        @node-click="handleClickNode"
        @node-expand="handleExpandNode"
        @node-collapse="handleCollapseNode"
      >
        <template #default="{ node, data }">
          <div class="node">
            <span class="treeLabelName" v-html="createLabelHtml(data.label)"></span>
            <!-- <Popover title="弹出层">
              <span slot="reference" class="options">操作</span>
            </Popover> -->

            <span class="options">
              <span class="label">操作</span>

              <div class="more">
                <div
                  v-if="data.level !== CATEGORY_TYPE.THREE"
                  class="moreItem"
                  @click.stop="handleClickCreate(data, node)"
                >
                  新增子级垂类
                </div>
                <div class="moreItem" @click.stop="handleClickEdit(data, node)">编辑</div>
                <div class="moreItem del" @click.stop="handleClickDeleted(data, node)">停用</div>
              </div>
            </span>
          </div>
        </template>
      </Tree>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.searchTreeContainer {
  height: calc(100% - 45px);
  ::v-deep {
    .el-tree-node__content {
      height: auto;
      margin: 5px 0;
    }

    .el-input__inner::placeholder {
      color: #a5a4aa;
    }

    .el-tree-node__content {
      border-radius: 4px;
    }
    .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
      background: rgba(145, 85, 253, 0.08);
    }

    .el-tree-node__content:hover {
      background: #f6f7f8;
    }

    .treeIcon {
      margin-left: 5px;
      display: inline-block;
      transform: none;
      width: 20px;
      height: 20px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('~@/assets/images/verticalCategorySetting/hideTreeIcon@3x.png');
    }

    .el-tree-node__expand-icon.is-leaf {
      background-image: none;
    }

    .el-tree-node > .el-tree-node__children {
      overflow: visible;
    }

    .el-tree-node__expand-icon.expanded {
      background-image: url('~@/assets/images/verticalCategorySetting/openTreeIcon@3x.png');
    }
  }
  .treeLabelName {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tag {
    opacity: 0;
    height: 1px;
  }

  .treeWrap {
    z-index: 10;
    box-sizing: border-box;
    overflow-y: scroll;

    .treeNodeWrap {
      height: 100%;
      padding-bottom: 120px;
    }
  }
  .node {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;

    .options {
      display: none;
      position: relative;
      padding: 2px 10px;

      .label {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #9155fd;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }

      .more {
        display: none;
        width: 140px;
        background: #ffffff;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 1px solid #e8e9ec;
        position: absolute;
        top: 25px;
        right: 0;
        z-index: 9999;
        padding: 10px 0;

        .moreItem {
          width: 100%;
          height: 32px;
          padding: 6px 16px;
          box-sizing: border-box;
          line-height: 32px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          line-height: 20px;
          text-align: left;
          font-style: normal;

          &:hover {
            background: #f6f7f8;
          }

          &.del {
            color: #f24545;
          }
        }
      }

      &:hover .more {
        display: inline-block;
      }
    }

    &:hover .options {
      display: inline-block;
    }
  }
}

.active {
  color: rgb(145, 85, 253);
}
</style>
