<script>
import { isNumber } from '@prettyy/utils'

export default {
  name: 'VlTableItem',
  props: {
    labelWidth: {
      type: String,
      default: '160px',
    },
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    text: {
      type: [String, Number],
      default: '',
    },
    isLink: Boolean, // 是否是链接
    required: Boolean,
  },
  computed: {
    labelWidthItem() {
      if (isNumber(this.labelWidth)) return `${this.labelWidth}px`
      if (this.labelWidth && !this.labelWidth.endsWith('px')) return `${this.labelWidth}px`

      return this.labelWidth
    },
  },
  methods: {
    jumpChannel() {
      if (this.text === '-' || typeof this.text !== 'string' || !this.isLink) return false
      window.open(this.text, '_blank')
    },
  },
}
</script>

<template>
  <tr>
    <td class="table-item" :style="{ width: labelWidthItem }">
      <div style="min-height: 44px;display: flex;align-items: center; flex-shrink: 0" :class="[required ? 'required' : '', labelClass]">
        {{ label }}
      </div>
    </td>
    <td class="table-value">
      <div class="flex-left" style="height: 100%" @click="jumpChannel">
        <slot>
          <span v-if="isLink" class="download-link">{{ text }}</span>
          <span v-else>{{ text }}</span>
        </slot>
      </div>
    </td>
  </tr>
</template>

<style scoped lang="scss">
.table-item {
  background: #F7F9FB;
  padding: 0 20px;
  font-size: 14px;
  min-height: 30px;
  flex-shrink: 0;

  .required {
    gap: 4px;

    &::before {
      content: '*';
      color: #ff0000;
    }
  }
}

.table-value {
  padding: 6px 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  text-align: left;
  flex: auto;
  font-style: normal;

  ::v-deep .v-text-field__details {
    display: none;
  }
}

th, td {
  border: 1px solid #E7ECF0;
}
</style>
