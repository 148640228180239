<script>
import {
  defineComponent, ref,
} from '@vue/composition-api'
import { convertSecondsToHMS } from '@core/utils'
import tableCols from './tableCols'
import useSearch from './useSearch'
import Setting from './components/Setting.vue'

export default defineComponent({
  components: {
    Setting,
  },
  setup() {
    const showSetting = ref(false)
    const currItem = ref({})

    const handleSetting = row => {
      showSetting.value = true
      currItem.value = row
    }

    return {
      ...useSearch(),
      convertSecondsToHMS,
      tableCols,
      showSetting,
      currItem,
      handleSetting,
    }
  },
})
</script>

<template>
  <AppView>
    <template #header>
      <div style="width: 100%">
        <!--搜索区域-->
        <XwSearch
          ref="searchRef"
          :form-config="searchConfig"
          @search="search"
        />
      </div>
    </template>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <XwTable
      ref="tableRef"
      :columns="tableCols"
      :rows="pageList"
      :row-config="{height: 80}"
      height="auto"
    >
      <template #video="{row}">
        <div
          class="video-box"
        >
          <div class="video-img">
            <v-img
              :src="row.img"
              width="105"
              height="59"
            />
            <div
              v-if="row.duration"
              class="video-duration"
            >
              {{ convertSecondsToHMS(row.duration) }}
            </div>
          </div>
          <div class="video-info ml-2">
            <div
              class="video-title"
              :title="row.title"
            >
              {{ row.title }}
            </div>
            <div class="video-size">
              ID:{{ row.videoId }}
            </div>
          </div>
        </div>
      </template>
      <template #time="{row}">
        <div>
          源视频创建时间：{{ row.publishedAt || '-' }}<br />
          发布时间：{{ row.createdAt || '-' }}
        </div>
      </template>
      <template #sourceChannelName="{row}">
        <div class="video-channel">
          <XwPlatform
            :platform="row.sourcePlatform"
            :show-name="false"
          ></XwPlatform>{{ row.sourceChannelName }}
        </div>
      </template>
      <template #channelName="{row}">
        <div class="video-channel">
          <XwPlatform
            :platform="row.publishPlatform"
            :show-name="false"
          ></XwPlatform>{{ row.publishChannelName }}
        </div>
      </template>
    </XwTable>

    <template #footer>
      <new-pagination
        :page-index="page"
        :page-size="pageSize"
        :total="pageLength"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
    <!--设置垂类-->
    <Setting
      v-if="showSetting"
      v-model="showSetting"
      :curr-item="currItem"
      :category-options="categoryOptions"
      @success="handleRefresh"
    />
  </AppView>
</template>
<style lang="scss" scoped>
.video{
    &-box{
      height: 60px;
      display: flex;
      text-decoration: none;
    }
    &-preview{
      height: 60px;
      height: 105px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
    }
    &-duration{
      position: absolute;
      right: 0;
      top: 0;
      min-width: 40px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 4px;
      background: rgba(0,0,0, 0.5);
      font-size: 12px;
      color: #fff;
      z-index: 1;
    }
    &-info{
      line-height: 20px;
    }
    &-title{
      height: 40px;
      line-height: 20px;
      color: rgba(0,0,0,0.88);
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 200px;
    }
    &-size{
      color: rgba(0,0,0,0.4);
    }
    &-img{
      width: 105px;
      height: 59px;
      background-color: #ddd;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
    }
    &-channel{
      display: flex;
      align-items: center;
    }
  }
</style>
