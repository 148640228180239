<script>
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { Button, Upload } from 'element-ui'
import { useMessage } from '@/hooks/useMessage'
import ExportTemplateError from './ExportTemplateError.vue'
import ProgressDialog from './ProgressDialog.vue'
import { getUploadTemplateRequest, UploadTemplateFileRequest } from '../api'

export default defineComponent({
  components: {
    Button,
    Upload,
    ExportTemplateError,
    ProgressDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeType: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const templateHref = ref(null)
    const errorDialog = ref(false)
    const loadingDialog = ref(false)
    const isCloseProgressDialog = ref(false)
    const fileList = ref([])
    const errorList = ref([])
    const errorFileUrl = ref(null)
    const errorMsg = ref(null)
    const { message } = useMessage()

    const getTemplateUrl = async () => {
      const result = await getUploadTemplateRequest()

      if (!result || result.status !== 200) {
        return
      }

      const {
        data: { data },
      } = result

      templateHref.value = data
    }

    const handleClose = () => {
      fileList.value = []
      emit('close')
    }

    const closeErrorDialog = () => {
      errorList.value = []
      errorFileUrl.value = null
      errorDialog.value = false
    }

    const openLoading = () => {
      handleClose()
      isCloseProgressDialog.value = false
      loadingDialog.value = true
    }

    const closeLoading = () => {
      isCloseProgressDialog.value = true
    }

    const closeLoadingDialog = () => {
      loadingDialog.value = false
      isCloseProgressDialog.value = false

      if (errorList.value.length === 0 && !errorMsg.value) {
        message.success('导入成功')

        return
      }

      if (errorList.value.length === 0 && errorMsg.value) {
        message.error(errorMsg.value)

        return
      }

      errorDialog.value = true
    }

    const uploadFile = async () => {
      const file = fileList.value[0]
      const form = new FormData()

      form.append('file', file)
      form.append('type', props.activeType)

      openLoading()
      try {
        const { data, status } = await UploadTemplateFileRequest(form)
        closeLoading()

        if (status !== 200) {
          return Promise.reject()
        }
        const {
          data: { url, data: list, resultCode, message: errMsg },
        } = data

        if (resultCode === 0) {
          emit('submit')

          errorMsg.value = null
          errorFileUrl.value = null
          errorList.value = []

          return Promise.reject()
        }

        errorMsg.value = errMsg
        errorFileUrl.value = url
        errorList.value = list.map(i => ({
          row: i.lineNumber,
          errorMsg: i.errorMsg,
        }))

        return Promise.resolve()
      } catch (err) {
        closeLoading()
      }
      closeLoading()
    }

    const handleSubmit = async () => {
      if (!fileList.value || fileList.value.length === 0) {
        return message.error('请上传文件')
      }

      await uploadFile()

      return Promise.resolve()
    }

    const getFileExtension = filename => {
      const lastIndex = filename.lastIndexOf('.')
      if (lastIndex === -1) {
        return null // 如果没有找到 '.'，则返回空字符串
      }

      return `.${filename.substring(lastIndex + 1)}`
    }

    const handleBeforeUpload = file => {
      const { size, name } = file
      const extendsStaring = getFileExtension(name)

      if (!extendsStaring || !['.xlsx', '.xls'].includes(extendsStaring)) {
        message.error('仅支持xls和xlsx格式文件上传')

        return Promise.reject()
      }

      if (size > 5 * 1024 * 1024) {
        message.error('最大上传5M的文件')

        return Promise.reject()
      }

      fileList.value = [...fileList.value, file]

      return Promise.resolve()
    }

    const handleUpdate = () => {}

    const handleRemove = file => {
      const { uid } = file
      fileList.value = fileList.value.filter(i => i.uid !== uid)
    }

    onMounted(() => {
      getTemplateUrl()
    })

    return {
      templateHref,
      errorFileUrl,
      errorList,
      errorDialog,
      loadingDialog,
      isCloseProgressDialog,

      handleSubmit,
      fileList,
      handleClose,
      handleBeforeUpload,
      handleRemove,
      handleUpdate,
      closeLoadingDialog,
      closeErrorDialog,
    }
  },
})
</script>

<template>
  <div>
    <vl-dialog :visible="visible" title="导入" width="400px" top="300px" @close="$emit('close')">
      <div class="exportDialogWrap">
        <div class="txt">
          1、下载 <a :href="templateHref" class="linkText"><导入创建发布通道模板></a>，填写相关字段信息；
        </div>
        <div class="txt">2、导入模板，单次导入限1000条，大小不能超过5M。</div>
      </div>

      <div class="mt-4">
        <Upload
          :file-list="fileList"
          action="''"
          :before-upload="handleBeforeUpload"
          :http-request="handleUpdate"
          :limit="1"
          :on-remove="handleRemove"
          :show-file-list="false"
          accept=".xlsx,.xls"
        >
          <Button :disabled="fileList.length !== 0" size="small" icon="icon-a-shangchuan2x iconfont mr-2">
            <slot>点击上传</slot>
          </Button>
        </Upload>

        <div v-if="fileList.length !== 0" class="fileList">
          <div v-for="item in fileList" :key="item.id" class="fileItem">
            <div class="label" :underline="false">{{ item.name }}</div>

            <div class="del" @click="handleRemove(item)">
              <i class="iconfont icon-a-shanchu2x" />
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="flex-right gap8">
          <v-btn outlined @click="handleClose"> 取消 </v-btn>
          <v-btn color="primary" @click="handleSubmit"> 确认 </v-btn>
        </div>
      </template>
    </vl-dialog>

    <div>
      <ExportTemplateError
        :list="errorList"
        :url="errorFileUrl"
        :visible.sync="errorDialog"
        @close="closeErrorDialog"
      />

      <ProgressDialog :is-close="isCloseProgressDialog" :visible="loadingDialog" @close="closeLoadingDialog" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .vl-dialog__body {
    padding: 24px 0;
  }
  .el-upload-list {
    padding: 0;
    background: #f6f7f8;
  }
}
.exportDialogWrap {
  .txt {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.linkText {
  color: #2ca0f8ff;
  text-decoration: none;
}

.fileList {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .fileItem {
    width: 100%;
    height: 30px;
    background: #f6f7f8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 4px 12px;
    margin-bottom: 8px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    .label {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 22px;
      text-align: left;
      font-style: normal;
      cursor: pointer;

      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        color: #9155fd;
      }
    }

    .del {
      cursor: pointer;
    }
  }
}
</style>
